import React from "react";
import { Col } from "react-bootstrap";
import { Droppable } from "react-beautiful-dnd";
import styled from "styled-components";
import TeamItem from "./TeamItem";

const Container = styled.div`
  border: 1px solid darkgrey;
  border-radius: 3px;
  margin-bottom: 8px;
  min-height: 10vh;
  max-height: 84vh;
  overflow-y: auto;
  clear: both;
`;
const Title = styled.h3`
  padding: 8px;
  float: left;
`;
const TeamList = styled.div`
  padding: 8px;
`;
const SubmitButton = styled.button`
  float: right;
  margin-top: 0.5rem;
`;
const DragHere = styled.p`
  padding: 8px;
`;

const PollColumn = (props) => {
  const { column, teamDetails, showRank, onSubmit } = props;

  const Header = () => {
    return column.id === "my-ballot" ? (
      <div>
        <Title>{column.title}</Title>
        <SubmitButton className="btn btn-primary" onClick={onSubmit}>
          Submit Ballot
        </SubmitButton>
      </div>
    ) : (
      <Title>{column.title}</Title>
    );
  };

  const EmptyBallotInstructions = () =>
    column.id === "my-ballot" && teamDetails.length === 0 ? (
      <DragHere>Drag teams below to begin your rankings</DragHere>
    ) : null;

  return (
    <Col className="col-sm-6 col-md-3">
      <Header />
      <Container className={column.id === "my-ballot" ? "lightBlue" : null}>
        <EmptyBallotInstructions />
        <Droppable droppableId={column.id}>
          {(provided) => {
            return (
              <TeamList ref={provided.innerRef} {...provided.droppableProps}>
                {teamDetails.map((teamDetail, index) => (
                  <TeamItem
                    key={teamDetail.team.id}
                    teamDetail={teamDetail}
                    index={index}
                    showRank={showRank}
                  />
                ))}
                {provided.placeholder}
              </TeamList>
            );
          }}
        </Droppable>
      </Container>
    </Col>
  );
};

export default PollColumn;
