import React from "react";
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";
import nonAqTeamIds from "./Data/nonAqTeamIds";

const Container = styled.div`
  border: 1px solid darkgrey;
  border-radius: 3px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: white;
  overflow: overlay;
`;

const RankContainer = styled.div`
  width: 15px;
  margin-right: 8px;
  float: left;
  font-weight: bold;
`;

const Record = styled.div`
  float: right;
  font-size: 0.8rem;
`;

const Tiny = styled.span`
  font-size: 0.6rem;
`;

const TeamItem = (props) => {
  const { index, teamDetail, showRank } = props;

  const Rank = () =>
    showRank ? <RankContainer>{index + 1}</RankContainer> : null;

  const TeamName = () => {
    return nonAqTeamIds.includes(teamDetail.team.id) ? (
      <React.Fragment>
        {teamDetail.team.name} <Tiny>[NAQ]</Tiny>
      </React.Fragment>
    ) : (
      teamDetail.team.name
    );
  };

  return (
    <Draggable draggableId={teamDetail.team.id.toString()} index={index}>
      {(provided) => {
        return (
          <Container
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Rank />
            <TeamName />
            <Record>{teamDetail.record}</Record>
          </Container>
        );
      }}
    </Draggable>
  );
};

export default TeamItem;
