import React from "react";
import StatsTable from "./StatsTable";

const TeamStarPlayerStats = (props) => {
  const { data } = props;

  data.map((r) => {
    r.calculated = {
      casPlusMinus:
        r.seasonStatistics.casualties - r.seasonStatistics.casualtiesAgainst,
      tdPlusMinus:
        r.seasonStatistics.touchdowns - r.seasonStatistics.touchdownsAgainst,
    };
    return r;
  });

  const columns = [
    { Header: "", accessor: "team.id", show: false },
    {
      Header: "Name",
      accessor: "team.name",
      fixed: "left",
      minWidth: 250,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/team?team_id=${row.original.team.id}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Race",
      accessor: "team.roster.name",
      filterable: true,
      sortable: false,
      minWidth: 150,
    },
    {
      Header: "Season SPP",
      accessor: "seasonStatistics.spp",
      className: "txt-rt",
    },
    {
      Header: "Total SPP",
      accessor: "seasonStatistics.totalSpp",
      className: "txt-rt",
    },
    {
      Header: "TD",
      accessor: "seasonStatistics.touchdowns",
      className: "txt-rt",
    },
    {
      Header: "TD +/-",
      accessor: "calculated.tdPlusMinus",
      className: "txt-rt",
    },
    {
      Header: "Cas",
      accessor: "seasonStatistics.casualties",
      className: "txt-rt",
    },
    {
      Header: "Cas +/-",
      accessor: "calculated.casPlusMinus",
      className: "txt-rt",
    },
    {
      Header: "Cp",
      accessor: "seasonStatistics.completions",
      className: "txt-rt",
    },
    {
      Header: "Int",
      accessor: "seasonStatistics.interceptions",
      className: "txt-rt",
    },
  ];

  return (
    <StatsTable
      columns={columns}
      data={data}
      defaultSorted={[{ desc: true, id: "seasonStatistics.spp" }]}
    />
  );
};

export default TeamStarPlayerStats;
