import React from "react";

const StatlineTable = (props) => {
  const { player } = props;
  
  return (
    <table className="table table-bordered table-sm txt-ctr">
      <thead className="thead-light">
        <tr>
          <th>MA</th>
          <th>ST</th>
          <th>AG</th>
          <th>PA</th>
          <th>AV</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{player.statline.displayStatline.MA}</td>
          <td>{player.statline.displayStatline.ST}</td>
          <td>{player.statline.displayStatline.AG}</td>
          <td>{player.statline.displayStatline.PA}</td>
          <td>{player.statline.displayStatline.AV}</td>
        </tr>
      </tbody>
    </table>
  );
};

export default StatlineTable;
