import React from "react";
import { Col } from "react-bootstrap";
import styled from "styled-components";
import { Droppable } from "react-beautiful-dnd";
import TeamDetails from "./TeamDetails";

const Container = styled.div`
  padding: 8px;
  border: 1px solid darkgrey;
  border-radius: 3px;
  max-height: 84vh;
  overflow-y: auto;
`;
const Title = styled.h3`
  padding: 8px;
`;
const TeamDetailsContainer = styled.div`
  max-height: 87vh;
`;
const DragHere = styled.div`
  border: 1px solid darkgrey;
  border-radius: 3px;
  padding: 8px;
  background-color: lightgrey;
  margin-bottom: 8px;
`;

const DetailsColumn = (props) => {
  const { column, teamDetail } = props;
  return (
    <Col className="col-sm-6 col-md-3">
      <Title>Compare Teams</Title>
      <Container>
        <Droppable droppableId={column.id}>
          {(provided) => {
            return (
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <DragHere>Drag team here to view details</DragHere>
                <TeamDetailsContainer>
                  <TeamDetails teamDetail={teamDetail} />
                </TeamDetailsContainer>
                {provided.placeholder}
              </div>
            );
          }}
        </Droppable>
      </Container>
    </Col>
  );
};

export default DetailsColumn;
