const ballotData = {
  teams: {},
  pollColumns: {
    "all-teams": {
      id: "all-teams",
      title: "Team List",
      teamIds: [],
    },
    "my-ballot": {
      id: "my-ballot",
      title: "My Ballot",
      teamIds: [],
    },
  },
  pollColumnOrder: ["my-ballot", "all-teams"],
  detailColumns: {
    "compare-left": {
      id: "compare-left",
      title: "Compare Team",
      teamDetail: {},
    },
    "compare-right": {
      id: "compare-right",
      title: "Compare Team",
      teamDetail: {},
    },
  },
  detailColumnOrder: ["compare-left", "compare-right"],
};

export default ballotData;
