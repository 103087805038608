import React, { useEffect, useState } from "react";
import pollData from "./Data/pollData";
import axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SwitchButton = styled.button`
  float: right;
`;

const Poll = () => {
  const pollId = pollData.previousPoll.id;

  const [pollBallots, setPollBallots] = useState(null);
  const [isPollBallotsLoaded, setIsPollBallotsLoaded] = useState(false);
  const [pollRankings, setPollRankings] = useState(null);

  useEffect(() => {
    axios
      .get(`/api/ballot?pollId=${pollId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setIsPollBallotsLoaded(true);
        setPollBallots(result.data);
      });
  }, [pollId]);

  useEffect(() => {
    if (isPollBallotsLoaded && pollBallots) {
      let teams = {};

      pollBallots.map((p) =>
        p.ballot.forEach((b) => {
          const { teamId, name, rank } = b;
          const points = teams[teamId] ? teams[teamId].points : 0;
          let fpv = teams[teamId] ? teams[teamId].firstPlacesVotes : 0;

          if (rank === 1) {
            fpv = fpv + 1;
          }

          teams[teamId] = {
            teamId: teamId,
            name: name,
            points:
              rank < 26 ? points + (26 - rank - 25 + p.ballot.length) : points,
            firstPlacesVotes: fpv,
          };
        })
      );

      const poll = Object.values(teams).sort((a, b) => {
        if (a.points > b.points) return -1;
        if (a.points < b.points) return 1;
        if (a.firstPlacesVotes > b.firstPlacesVotes) return -1;
        if (a.firstPlacesVotes < b.firstPlacesVotes) return 1;
        return 0;
      });

      const points = poll.map((t) => t.points);

      poll.forEach((t) => (t.rank = points.indexOf(t.points) + 1));

      setPollRankings(poll);
    }
  }, [isPollBallotsLoaded, pollBallots]);

  const columns = [
    {
      Header: "Rank",
      accessor: "rank",
      width: 75,
    },
    {
      Header: "Team",
      accessor: "name",
      Cell: (row) => {
        const num = parseInt(row.original.firstPlacesVotes);
        const rankStr = num > 0 ? ` (${num})` : "";
        return (
          <React.Fragment>
            <a
              target="_blank"
              rel="noreferrer noopener"
              href={`https://fumbbl.com/p/team?team_id=${row.original.teamId}`}
            >
              {row.original.name}
            </a>
            {rankStr}
          </React.Fragment>
        );
      },
    },
    {
      Header: "Points",
      accessor: "points",
      className: "txt-rt",
      width: 75,
    },
  ];

  const PollTable = () => {
    if (!pollRankings)
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    else {
      // console.log(pollRankings);
      return (
        <ReactTable
          className="-striped -highlight"
          data={pollRankings}
          columns={columns}
          showPagination={false}
          sortable={false}
          defaultPageSize={25}
          style={{ clear: "both" }}
        />
      );
    }
  };

  const OthersReceivingVotes = () => {
    if (!pollRankings) return null;

    const strOthers = pollRankings
      .filter((r) => r.rank > 25)
      .filter((r) => r.points > 0)
      .map((v) => `${v.name} ${v.points}`)
      .join(", ");

    return (
      <p>
        <b>Others receiving votes:</b> {strOthers}
      </p>
    );
  };

  if (pollId == null) {
    return (
      <h3 className="txt-ctr">
        There is currently no poll data to display. Please return here after the
        voting period has completed to see the poll results.
      </h3>
    );
  } else {
    return (
      <React.Fragment>
        <Row>
          <Col className="col-xl-6 col-lg-12 m-auto">
            <div>
              <h2 className="float-left">NCBB Coaches Poll - Top 25</h2>
              <Link to="/fumbbl/NCBB/poll/breakdown">
                <SwitchButton className="btn btn-primary float-right">
                  Voter Breakdown
                </SwitchButton>
              </Link>
            </div>
            <PollTable />
            <OthersReceivingVotes />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

export default Poll;
