import React from "react";
import { Table, Col, Row } from "react-bootstrap";

const Info = () => {
  return (
    <React.Fragment>
      <Row className="justify-content-md-center">
        <Col style={{ fontSize: "1.5rem" }}>
          <h1>When & Where</h1>
          <p>September 21 2024</p>
          <p>Stormcrow Games</p>
          <p>2155 A 50th Street</p>
          <p>Lubbock, TX 79412</p>
          <br />
          <br />
          <h1>Schedule</h1>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>10:00</td>
                <td>Registration</td>
              </tr>
              <tr>
                <td>10:30 – 12:40</td>
                <td>Round 1</td>
              </tr>
              <tr>
                <td>12:40 – 1:40</td>
                <td>Lunch</td>
              </tr>
              <tr>
                <td>1:40 – 3:50</td>
                <td>Round 2</td>
              </tr>
              <tr>
                <td>4:00 – 6:10</td>
                <td>Round 3</td>
              </tr>
              <tr>
                <td>6:30</td>
                <td>Results</td>
              </tr>
            </tbody>
          </Table>
          <br />
          <br />
          <h1>Cost</h1> <p>$30 – Pre-register by September 14th</p>
          <p>
            Please send your rosters to matadormelee@gmail.com before September
            14th.
          </p>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Info;
