import React from "react";

import { Navbar, Nav, NavDropdown, NavLink } from "react-bootstrap";

const Layout = (props) => {
  const leagueKey =
    props.children.length > 1
      ? "NCBB"
      : props.children.props.match.params.leagueKey;

  const Statistics = () => {
    if (leagueKey === "NCBB") {
      return (
        <NavDropdown title="Statistics" id="basic-nav-dropdown">
          <NavDropdown.Item href="/fumbbl/NCBB/stats">
            Season Statistics
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/NCBB/bigboard">
            Big Board
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/NCBB/leaders">
            All-Time Leaders
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/NCBB/leadersSingleSeason">
            Single Season Leaders
          </NavDropdown.Item>
        </NavDropdown>
      );
    } else if (leagueKey === "NWBL") {
      return (
        <NavDropdown title="Statistics" id="basic-nav-dropdown">
          <NavDropdown.Item href="/fumbbl/NWBL/stats">
            Season Statistics
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/NWBL/leaders">
            All-Time Leaders
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/NWBL/leadersSingleSeason">
            Single Season Leaders
          </NavDropdown.Item>
        </NavDropdown>
      );
    } else if (leagueKey === "NBFL") {
      return (
        <NavDropdown title="Statistics" id="basic-nav-dropdown">
          <NavDropdown.Item href="/fumbbl/NBFL/stats">
            Season Statistics
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/NBFL/leaders">
            All-Time Leaders
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/NBFL/leadersSingleSeason">
            Single Season Leaders
          </NavDropdown.Item>
        </NavDropdown>
      );
    } else if (leagueKey === "DLE") {
      return (
        <NavDropdown title="Statistics" id="basic-nav-dropdown">
          <NavDropdown.Item href="/fumbbl/DLE/stats">
            Season Statistics
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/DLE/leaders">
            All-Time Leaders
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/DLE/leadersSingleSeason">
            Single Season Leaders
          </NavDropdown.Item>
        </NavDropdown>
      );
    } else if (leagueKey === "FDL") {
      return (
        <NavDropdown title="Statistics" id="basic-nav-dropdown">
          <NavDropdown.Item href="/fumbbl/FDL/stats">
            Season Statistics
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/FDL/leaders">
            All-Time Leaders
          </NavDropdown.Item>
          <NavDropdown.Item href="/fumbbl/FDL/leadersSingleSeason">
            Single Season Leaders
          </NavDropdown.Item>
        </NavDropdown>
      );
    }
  };

  const Rankings = () => {
    if (leagueKey === "NCBB") {
      return (
        <React.Fragment>
          <NavLink href="/fumbbl/NCBB/ranking">Rankings</NavLink>
          {/* <NavDropdown title="Rankings" id="basic-nav-dropdown">
            <NavDropdown.Item href="/fumbbl/NCBB/ranking">
              HyperboLemuR Rankings
            </NavDropdown.Item>
            <NavDropdown.Item
              target="_blank"
              rel="noopener noreferrer"
              href="https://fumbbl.com/note/Uber/NCBB"
            >
              Other NCBB Rankings&nbsp;
              <i
                className="fas fa-external-link-alt"
                style={{ fontSize: "small" }}
              ></i>
            </NavDropdown.Item>
          </NavDropdown> */}
          <NavDropdown title="Polls" id="basic-nav-dropdown2">
            <NavDropdown.Item href="/fumbbl/NCBB/poll">
              Coaches Poll
            </NavDropdown.Item>
            <NavDropdown.Item href="/fumbbl/NCBB/poll/mcnurgle">
              McNurgles Poll
            </NavDropdown.Item>
            <NavDropdown.Item href="/fumbbl/NCBB/poll/ballot">
              Submit a Coaches Poll Ballot
            </NavDropdown.Item>
            <NavDropdown.Item href="/fumbbl/NCBB/poll/ballot/mcnurgle">
              Submit a McNurgle's Poll Ballot
            </NavDropdown.Item>
          </NavDropdown>
        </React.Fragment>
      );
    } else {
      return null;
    }
  };

  return (
    <React.Fragment>
      <Navbar bg="light" className="mb-3">
        <Navbar.Brand href="/">HyperboLemuR Fumbbl Stats</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title={leagueKey} id="basic-nav-dropdown">
              <NavDropdown.Item href="/fumbbl/NCBB/stats">
                NCBB
              </NavDropdown.Item>
              <NavDropdown.Item href="/fumbbl/NWBL/stats">
                NWBL
              </NavDropdown.Item>
              <NavDropdown.Item href="/fumbbl/NBFL/stats">
                NBFL
              </NavDropdown.Item>
              <NavDropdown.Item href="/fumbbl/DLE/stats">DLE</NavDropdown.Item>
              <NavDropdown.Item href="/fumbbl/FDL/stats">FDL</NavDropdown.Item>
            </NavDropdown>
            <Statistics />
            <Rankings />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      {props.children}
    </React.Fragment>
  );
};

export default Layout;
