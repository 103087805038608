import React from "react";
import StatsTable from "./StatsTable";

const OffenseStats = (props) => {
  const { data } = props;

  data.map((r) => {
    r.calculated = {
      passingYardsPerCompletion:
        r.seasonStatistics.completions < 3
          ? null
          : (
              r.seasonStatistics.passing / r.seasonStatistics.completions
            ).toFixed(3),
      passingYardsPerGame: (
        r.seasonStatistics.passing / r.seasonStatistics.games
      ).toFixed(3),
      rushingYardsPerGame: (
        r.seasonStatistics.rushing / r.seasonStatistics.games
      ).toFixed(3),
      totalYards: r.seasonStatistics.rushing + r.seasonStatistics.passing,
    };
    return r;
  });

  const columns = [
    {
      Header: "",
      accessor: "player.id",
      show: false,
    },
    {
      Header: "",
      accessor: "player.teamId",
      show: false,
    },
    {
      Header: "Name",
      accessor: "player.name",
      fixed: "left",
      minWidth: 200,
      filterable: true,
      sortable: false,
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/player?player_id=${row.original.player.id}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Team",
      accessor: "team.name",
      minWidth: 250,
      filterable: true,
      sortable: false,
      fixed: "left",
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/team?team_id=${row.original.player.teamId}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Position",
      accessor: "player.position.name",
      minWidth: 150,
      filterable: true,
      sortable: false,
    },
    {
      Header: "Race",
      accessor: "team.roster.name",
      filterable: true,
      sortable: false,
      minWidth: 150,
    },
    // TODO: Conference
    { Header: "Status", accessor: "player.status", filterable: true },
    {
      Header: "TD",
      accessor: "seasonStatistics.touchdowns",
      className: "txt-rt",
    },
    {
      Header: "Total Yd",
      accessor: "calculated.totalYards",
      className: "txt-rt",
    },
    {
      Header: "CP",
      accessor: "seasonStatistics.completions",
      className: "txt-rt",
    },
    {
      Header: "Passing",
      accessor: "seasonStatistics.passing",
      className: "txt-rt",
    },
    {
      Header: "Pass YPC",
      accessor: "calculated.passingYardsPerCompletion",
      sortMethod: (a, b) => Number(a) - Number(b),
      className: "txt-rt",
    },
    {
      Header: "Pass YPG",
      accessor: "calculated.passingYardsPerGame",
      sortMethod: (a, b) => Number(a) - Number(b),
      className: "txt-rt",
    },
    {
      Header: "Rushing",
      accessor: "seasonStatistics.rushing",
      className: "txt-rt",
    },
    {
      Header: "Rush YPG",
      accessor: "calculated.rushingYardsPerGame",
      sortMethod: (a, b) => Number(a) - Number(b),
      className: "txt-rt",
    },
    {
      Header: "Games",
      accessor: "seasonStatistics.games",
      className: "txt-rt",
    },
    {
      Header: "Turns",
      accessor: "seasonStatistics.turns",
      className: "txt-rt",
    },
  ];

  return (
    <StatsTable
      columns={columns}
      data={data}
      defaultSorted={[{ desc: true, id: "seasonStatistics.touchdowns" }]}
    />
  );
};

export default OffenseStats;
