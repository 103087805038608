import React from "react";
import StatsTable from "./StatsTable";

const HittingStats = (props) => {
  const { data } = props;

  data.map((r) => {
    r.calculated = {
      blocksPerGame: (
        r.seasonStatistics.blocks / r.seasonStatistics.games
      ).toFixed(3),
      casPerBlock:
        r.seasonStatistics.casualties < 3
          ? null
          : r.seasonStatistics.casualties / r.seasonStatistics.blocks,
      casPerGame: (
        r.seasonStatistics.casualties / r.seasonStatistics.games
      ).toFixed(3),
      foulsPerGame: (
        r.seasonStatistics.fouls / r.seasonStatistics.games
      ).toFixed(3),
      foulsPerTurn:
        r.seasonStatistics.fouls < 3
          ? null
          : r.seasonStatistics.fouls / r.seasonStatistics.turns,
    };
    return r;
  });

  const columns = [
    {
      Header: "",
      accessor: "player.id",
      show: false,
    },
    {
      Header: "",
      accessor: "player.teamId",
      show: false,
    },
    {
      Header: "Name",
      accessor: "player.name",
      fixed: "left",
      minWidth: 200,
      filterable: true,
      sortable: false,
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/player?player_id=${row.original.player.id}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Team",
      accessor: "team.name",
      minWidth: 250,
      filterable: true,
      sortable: false,
      fixed: "left",
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/team?team_id=${row.original.player.teamId}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Position",
      accessor: "player.position.name",
      minWidth: 150,
      filterable: true,
      sortable: false,
    },
    {
      Header: "Race",
      accessor: "team.roster.name",
      filterable: true,
      sortable: false,
      minWidth: 150,
    },
    // TODO: Conference
    { Header: "Status", accessor: "player.status", filterable: true },
    {
      Header: "Cas",
      accessor: "seasonStatistics.casualties",
      className: "txt-rt",
    },
    {
      Header: "CasPG",
      accessor: "calculated.casPerGame",
      className: "txt-rt",
    },
    {
      Header: "Blocks",
      accessor: "seasonStatistics.blocks",
      className: "txt-rt",
    },
    {
      Header: "BPG",
      accessor: "calculated.blocksPerGame",
      sortMethod: (a, b) => Number(a) - Number(b),
      className: "txt-rt",
    },
    {
      Header: "BPCas",
      accessor: "calculated.casPerBlock",
      Cell: (row) => (row.value === null ? null : (1 / row.value).toFixed(3)),
      className: "txt-rt",
    },
    {
      Header: "Fouls",
      accessor: "seasonStatistics.fouls",
      className: "txt-rt",
    },
    {
      Header: "FPG",
      accessor: "calculated.foulsPerGame",
      className: "txt-rt",
    },
    {
      Header: "TPF",
      accessor: "calculated.foulsPerTurn",
      Cell: (row) => (row.value === null ? null : (1 / row.value).toFixed(3)),
      className: "txt-rt",
    },
    {
      Header: "Games",
      accessor: "seasonStatistics.games",
      className: "txt-rt",
    },
    {
      Header: "Turns",
      accessor: "seasonStatistics.turns",
      className: "txt-rt",
    },
  ];

  return (
    <StatsTable
      columns={columns}
      data={data}
      defaultSorted={[{ desc: true, id: "seasonStatistics.casualties" }]}
    />
  );
};

export default HittingStats;
