const mcNurgleData = {
  currentPoll: {
    // id: "S45-mcNurgle",
    id: null,
  },
  previousPoll: {
    id: "S45-mcNurgle",
    // id: null,
  },
};

export default mcNurgleData;
