import React, { useEffect, useState } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Popover, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import leagueSeasons from "./Data/leagueSeasons";
// import { Dropdown, DropdownButton } from "react-bootstrap";

const BigBoard = (props) => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [bigBoardData, setBigBoardData] = useState(null);

  const currentSeason = leagueSeasons
    .find((s) => s.leagueKey === "NCBB")
    .seasons.find((s) => s.current === true);
  // const seasonId = currentSeason.seasonId;

  const search = props.location.search;
  const params = new URLSearchParams(search);
  let seasonKey = params.get("season") ?? currentSeason.seasonKey;

  useEffect(() => {
    const seasonId = `ncbb-season-${seasonKey}`;
    axios
      .get(`/api/bigBoard?seasonId=${seasonId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setBigBoardData(
          result.data.sort((a, b) => {
            if (a.spp > b.spp) return -1;
            if (a.spp < b.spp) return 1;
            return 0;
          })
        );
        setIsDataLoaded(true);
      });
  }, [seasonKey]);

  const StatlineTable = (props) => {
    const { player } = props;

    if (player)
      return (
        <table className="table table-bordered table-sm txt-ctr">
          <thead className="thead-light">
            <tr>
              <th>MA</th>
              <th>ST</th>
              <th>AG</th>
              <th>PA</th>
              <th>AV</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{player.statline.displayStatline.MA}</td>
              <td>{player.statline.displayStatline.ST}</td>
              <td>{player.statline.displayStatline.AG}</td>
              <td>{player.statline.displayStatline.PA}</td>
              <td>{player.statline.displayStatline.AV}</td>
            </tr>
          </tbody>
        </table>
      );
    else return null;
  };

  function popover(player) {
    if (player)
      return (
        <Popover id="popover-basic">
          <Popover.Title as="h3">{player.playerName}</Popover.Title>
          <Popover.Content>
            <StatlineTable player={player} />
            <div className="txt-ctr">
              <span style={{ fontStyle: "italic" }}>
                {player.baseSkills.join(", ")}
                {player.baseSkills.length > 0 ? ", " : null}
              </span>
              {player.skills.join(", ")}
            </div>
          </Popover.Content>
        </Popover>
      );
    else return null;
  }

  const columns = [
    {
      Header: "Name",
      accessor: "playerName",
      className: "text-wrap",
      Cell: (row) => (
        <React.Fragment>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`https://fumbbl.com/p/player?player_id=${row.original.playerId}`}
          >
            {row.original.playerName}
          </a>
          <OverlayTrigger placement="right" overlay={popover(row.original)}>
            <i
              className="fas fa-info-circle ml-2"
              style={{ fontSize: "small", color: "rgba(0, 0, 0, 0.5)" }}
            ></i>
          </OverlayTrigger>
        </React.Fragment>
      ),
    },
    {
      Header: "SPP",
      accessor: "spp",
      className: "txt-ctr",
      width: 75,
    },
    {
      Header: "Position",
      accessor: "position",
      className: "text-wrap",
    },
    {
      Header: "Team",
      accessor: "teamName",
      className: "text-wrap",
      Cell: (row) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`https://fumbbl.com/p/team?op=view&team_id=${row.original.teamId}`}
        >
          {row.original.teamName}
        </a>
      ),
    },
    {
      Header: "Stats",
      accessor: "statIncreases",
      className: "txt-ctr text-wrap",
      Cell: (props) => props.value.join(", "),
    },
    {
      Header: "Note",
      accessor: "credentials",
      className: "text-wrap",
    },
    {
      Header: "Coach",
      accessor: "coachName",
      width: 150,
    },
  ];

  const BigBoardTable = () => {
    if (isDataLoaded) {
      return (
        <ReactTable
          className="-striped -highlight"
          columns={columns}
          data={bigBoardData}
          sortable={false}
          showPagination={false}
          defaultPageSize={500}
          minRows={1}
        />
      );
    } else {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center" }}>
        {/* <DropdownButton
          title={seasonKey == null ? "Season" : `Season ${seasonKey}`}
          className="seasonDropdownButton"
          style={{ float: "left" }}
        >
          {leagueSeasons
            .find((l) => l.leagueKey === "NCBB")
            .seasons.sort((a, b) => b.seasonKey - a.seasonKey)
            .map((s) => (
              <Dropdown.Item
                key={s.seasonKey}
                href={`/fumbbl/NCBB/bigboard?season=${s.seasonKey}`}
              >
                Season {`${s.seasonKey}`}
              </Dropdown.Item>
            ))}
        </DropdownButton> */}
        Players to Watch
      </h2>
      <BigBoardTable />
    </React.Fragment>
  );
};

export default BigBoard;
