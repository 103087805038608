import React, { useEffect, useState } from "react";
import { Alert, Form, Row, Col } from "react-bootstrap";
import axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";

const Redraft = () => {
  const [rostersData, setRostersData] = useState(null);
  const [selectedRace, setSelectedRace] = useState(null);
  const [teamsData, setTeamsData] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const [savedRedraft, setSavedRedraft] = useState(null);
  const [isSavedRedraftLoaded, setIsSavedRedraftLoaded] = useState(false);
  const [redraftSaved, setRedraftSaved] = useState(false);
  const [error, setError] = useState(null);
  const [validationErrors, setValidationErrors] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/nwblGroupTeams`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        var ret = result.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          else return 1;
        });
        setTeamsData(ret);
      });

    axios
      .get(`/api/nwblrosters`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        var ret = result.data.sort((a, b) => {
          if (a.name < b.name) return -1;
          else return 1;
        });
        setRostersData(ret);
      });
  }, []);

  useEffect(() => {
    if (redraftSaved) {
      alert("Redraft saved!");
      window.location.reload(true);
    }
  }, [redraftSaved]);

  useEffect(() => {
    if (isSavedRedraftLoaded && savedRedraft) {
      LoadSheet();
    }
  });

  function LoadSheet() {
    var budget = savedRedraft.redraft.budget;
    switch (budget) {
      case "1300000":
        document.getElementById("rbLeagueBronze").click();
        break;
      case "1400000":
        document.getElementById("rbLeagueSilver").click();
        break;
      case "1500000":
        document.getElementById("rbLeagueGold").click();
        break;
      default:
        console.error("budget not found");
    }

    var rookies = savedRedraft.redraft.rookies;

    rookies.forEach((r) => {
      if (r.count > 0) {
        var posId = r.positionId;
        var ddl = document.getElementById("ddlRookies_" + posId);
        ddl.value = r.count;
        UpdateRookieTotalCost(ddl);
      }
    });

    var chk = document.getElementById("chkGoldChamp");
    if (savedRedraft.redraft.goldLeagueChamp) {
      chk.click();
    }

    chk = document.getElementById("chkFaCupChamp");
    if (savedRedraft.redraft.faCupChamp) {
      chk.click();
    }

    chk = document.getElementById("chkSmallCupChamp");
    if (savedRedraft.redraft.smallCupChamp) {
      chk.click();
    }

    var ddl = document.getElementById("ddlRerolls");
    ddl.value = savedRedraft.redraft.rerolls;
    UpdateRerollCost(ddl);

    ddl = document.getElementById("ddlCoaches");
    ddl.value = savedRedraft.redraft.coaches;
    UpdateCoachesCost(ddl);

    ddl = document.getElementById("ddlCheerleaders");
    ddl.value = savedRedraft.redraft.cheerleaders;
    UpdateCheerleadersCost(ddl);

    ddl = document.getElementById("ddlFans");
    ddl.value = savedRedraft.redraft.fans;
    UpdateFansCost(ddl);

    var chkbox = document.getElementById("chkApothecary");
    chkbox.checked = savedRedraft.redraft.apothecary === true;
    UpdateApothCost(chkbox);

    var returningPlayers = savedRedraft.redraft.returningPlayers;
    returningPlayers.forEach((p) => {
      var chkbox = document.getElementById("chkRedraft_" + p);
      chkbox.checked = true;

      UpdateTotalCost(chkbox);
    });
  }

  function isFormValid(redraft) {
    var vErrors = [];
    var valid = true;
    var totalExp = parseInt(document.getElementById("txtTotalTotal").value);

    // required fields

    if (redraft.budget === "") {
      valid = false;
      vErrors.push("You must select a Tier for next season");
    } else if (totalExp > redraft.budget) {
      valid = false;
      vErrors.push("Your redraft is over budget");
    }

    let numPlayers = 0;

    redraft.rookies.forEach((r) => {
      numPlayers += parseInt(r.count);
    });

    redraft.returningPlayers.forEach((p) => numPlayers++);

    if (numPlayers < 11) {
      valid = false;
      vErrors.push("You must have at least 11 players on your roster");
    }

    if (numPlayers > 16) {
      valid = false;
      vErrors.push("You must have fewer than 17 players on your roster");
    }

    // check for max allowed positionals
    redraft.race.positions.forEach((p) => {
      if (p.quantity !== 0) {
        var rosteredPlayersInPosition = selectedTeam.players
          .filter((r) => r.positionId === p.id)
          .map((x) => x.id);

        var countReturningPlayersInPosition = rosteredPlayersInPosition.filter(
          (v) => redraft.returningPlayers.includes(v)
        ).length;

        var countRookiesInPosition = redraft.rookies.filter(
          (r) => parseInt(r.positionId) === p.id
        )[0].count;

        if (
          parseInt(countReturningPlayersInPosition) +
            parseInt(countRookiesInPosition) >
          parseInt(p.quantity)
        ) {
          valid = false;
          vErrors.push("Too many players for position: " + p.title);
        }
      }
    });

    if (!valid) {
      setValidationErrors(vErrors);
    }

    return valid;
  }

  function handleSubmit(e) {
    e.preventDefault();

    var newRedraft = {
      id: null,
      teamId: 0,
      redraft: {},
    };

    if (savedRedraft && savedRedraft.id != null) {
      newRedraft.id = savedRedraft.id;
    }

    var budget = document.getElementById("txtLeagueBudget").value;

    var redraftLists = document.querySelectorAll('[id^="chkRedraft"]');

    newRedraft.redraft.returningPlayers = [];
    redraftLists.forEach((s) => {
      if (s.checked) {
        var index = s.id.lastIndexOf("_");
        var playerId = s.id.substring(index + 1);

        newRedraft.redraft.returningPlayers.push(parseInt(playerId));
      }
    });

    var rookiesLists = document.querySelectorAll('[id^="ddlRookies"]');

    var rookies = Array(rookiesLists.length);

    var idx = 0;

    rookiesLists.forEach((s) => {
      var index = s.id.lastIndexOf("_");
      var positionId = s.id.substring(index + 1);
      var numRookies = s.value;

      var newRookie = {};
      newRookie.positionId = positionId;
      newRookie.count = numRookies;
      rookies[idx] = newRookie;
      idx = idx + 1;
    });

    var rerolls = document.getElementById("ddlRerolls").value;
    var coaches = document.getElementById("ddlCoaches").value;
    var cheerleaders = document.getElementById("ddlCheerleaders").value;
    var fans = document.getElementById("ddlFans").value;
    var apothecary = document.getElementById("chkApothecary").checked;

    newRedraft.teamId = selectedTeam.id.toString();
    newRedraft.redraft.team = selectedTeam;
    newRedraft.redraft.race = selectedRace;

    newRedraft.redraft.goldLeagueChamp =
      document.getElementById("chkGoldChamp").checked;
    newRedraft.redraft.faCupChamp =
      document.getElementById("chkFaCupChamp").checked;
    newRedraft.redraft.smallCupChamp =
      document.getElementById("chkSmallCupChamp").checked;

    newRedraft.redraft.rookies = rookies;
    newRedraft.redraft.budget = budget;
    newRedraft.redraft.rerolls = rerolls;
    newRedraft.redraft.coaches = coaches;
    newRedraft.redraft.cheerleaders = cheerleaders;
    newRedraft.redraft.fans = fans;
    newRedraft.redraft.apothecary = apothecary;

    setSavedRedraft(newRedraft);
    if (!isFormValid(newRedraft.redraft)) {
      alert("Redraft is invalid; See error list.");
      return;
    }

    if (savedRedraft == null || savedRedraft.id == null) {
      axios.post("/api/redraft", newRedraft).then(
        (response) => {
          setRedraftSaved(true);
          setSavedRedraft(newRedraft);
        },
        (error) => setError(error)
      );
    } else {
      axios.put(`/api/redraft/${savedRedraft.id}`, newRedraft).then(
        (response) => {
          setRedraftSaved(true);
          setSavedRedraft(newRedraft);
        },
        (error) => setError(error)
      );
    }
  }

  function handleTeamChange(e) {
    e.preventDefault();

    setIsSavedRedraftLoaded(false);
    setSavedRedraft(null);

    var teamId = document.getElementById(e.target.id).value;
    var team = teamsData.find((r) => r.id === parseInt(teamId));
    setSelectedTeam(teamsData.find((r) => r.id === parseInt(teamId)));

    var raceId = team.roster.id;
    var race = rostersData.find((r) => r.id === raceId);
    setSelectedRace(race);

    axios
      .get(`/api/redraft?teamId=${teamId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setIsSavedRedraftLoaded(true);
        if (result.data.length > 0) {
          setSavedRedraft(result.data[0]);
        } else {
          setSavedRedraft(null);
        }
      });
  }

  function handleCheckPlayer(e) {
    var chkbox = e.target;
    UpdateTotalCost(chkbox);
  }

  function UpdateTotalCost(chkbox) {
    var index = chkbox.id.lastIndexOf("_");
    var playerId = chkbox.id.substring(index + 1);

    var redraftCheckbox = document.getElementById("chkRedraft_" + playerId);

    if (redraftCheckbox.checked) {
      var positionId = selectedTeam.players.find(
        (p) => p.id === parseInt(playerId)
      ).positionId;
      var baseCost = selectedRace.positions.find(
        (p) => p.id === positionId
      ).cost;
      var seasons = document.getElementById("spnSeasons_" + playerId).innerText;
      var skillCost = parseInt(
        document.getElementById("spnSkillCost_" + playerId).innerText
      );

      document.getElementById("txtTotalCost_" + playerId).value =
        baseCost + 20000 * seasons + skillCost;
    } else {
      document.getElementById("txtTotalCost_" + playerId).value = "";
    }

    TotalTotal();
  }

  const columns = [
    {
      Header: "#",
      accessor: "number",
      width: 50,
    },
    {
      Header: "Name",
      accessor: "name",
      width: 300,
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://www.fumbbl.com/p/player?player_id=${row.original.id}`}
        >
          {row.original.name}
        </a>
      ),
    },
    {
      Header: "Position",
      accessor: "position",
      width: 300,
    },
    {
      Header: "Skills",
      accessor: "skills",
      Cell: (row) => <>{row.original.skills.join(", ")}</>,
    },
    {
      Header: "SPP",
      accessor: "record",
      width: 75,
      className: "txt-ctr",
      Cell: (row) => (
        <>{row.original.record.spp - row.original.record.spent_spp}</>
      ),
    },
    {
      Header: "Injuries",
      accessor: "injuries",
      width: 75,
    },
    {
      Header: "Base Cost",
      accessor: "positionId",
      width: 105,
      className: "txt-rt",
      Cell: (row) =>
        selectedRace.positions.find((p) => p.id === row.original.positionId)
          .cost,
    },
    {
      Header: "Redraft",
      width: 75,
      className: "txt-ctr",
      Cell: (row) => (
        <input
          type="checkbox"
          id={`chkRedraft_${row.original.id}`}
          onClick={handleCheckPlayer}
        ></input>
      ),
    },
    {
      Header: "Seasons",
      accessor: "seasonsPlayed",
      width: 75,
      className: "txt-ctr",
      Cell: (row) => (
        <span id={`spnSeasons_${row.original.id}`}>
          {row.original.seasonsPlayed}
        </span>
      ),
    },
    {
      Header: "Skill Cost",
      width: 120,
      className: "txt-ctr",
      Cell: (row) => (
        <span id={`spnSkillCost_${row.original.id}`}>
          {row.original.totalSkillsCost}
        </span>
      ),
    },
    {
      Header: "Total Cost",
      width: 90,
      className: "txt-rt",
      Cell: (row) => (
        <input
          type="text"
          readOnly
          id={`txtTotalCost_${row.original.id}`}
          style={{ width: "90%" }}
        ></input>
      ),
    },
  ];

  const PlayersTable = () => {
    return (
      selectedTeam && (
        <React.Fragment>
          <ReactTable
            className="-striped -highlight"
            columns={columns}
            data={selectedTeam.players.sort((a, b) => a.number - b.number)}
            sortable={false}
            showPagination={false}
            defaultPageSize={16}
            minRows={1}
          />
        </React.Fragment>
      )
    );
  };

  function handleRookieChange(e) {
    var ddl = e.target;
    UpdateRookieTotalCost(ddl);
  }

  function UpdateRookieTotalCost(ddl) {
    var index = ddl.id.lastIndexOf("_");
    var positionId = ddl.id.substring(index + 1);

    var positionCost = selectedRace.positions.find(
      (p) => p.id === parseInt(positionId)
    ).cost;

    var numRookies = ddl.value;

    document.getElementById("txtRookieTotalCost_" + positionId).value =
      positionCost * numRookies;

    TotalTotal();
  }

  const positionColumns = [
    {
      Header: "Position",
      accessor: "title",
    },
    {
      Header: "Max Allowed",
      accessor: "quantity",
      width: 150,
    },
    {
      Header: "Cost",
      accessor: "cost",
      width: 100,
    },
    {
      Header: "Rookies",
      width: 100,
      Cell: (row) => {
        var qty = new Array(row.original.quantity + 1).fill(0);

        return (
          <select
            id={`ddlRookies_${row.original.id}`}
            onChange={handleRookieChange}
          >
            {qty.map((_, i) => {
              var iKey = row.original.id + "_" + i;
              return <option key={iKey}>{i}</option>;
            })}
          </select>
        );
      },
    },
    {
      Header: "Total",
      className: "txt-rt",
      width: 100,
      Cell: (row) => (
        <input
          type="text"
          readOnly
          id={`txtRookieTotalCost_${row.original.id}`}
          style={{ width: "90%" }}
        ></input>
      ),
    },
  ];

  function handleRerollChange(e) {
    var ddl = e.target;
    UpdateRerollCost(ddl);
  }

  function UpdateRerollCost(ddl) {
    document.getElementById("txtRerollTotalCost").value =
      ddl.value * selectedRace.rerollCost;
    TotalTotal();
  }

  function handleCoachesChange(e) {
    var ddl = e.target;
    UpdateCoachesCost(ddl);
  }

  function UpdateCoachesCost(ddl) {
    document.getElementById("txtCoachTotalCost").value = ddl.value * 10000;
    TotalTotal();
  }

  function handleCheerleadersChange(e) {
    var ddl = e.target;
    UpdateCheerleadersCost(ddl);
  }

  function UpdateCheerleadersCost(ddl) {
    document.getElementById("txtCheerleaderTotalCost").value =
      ddl.value * 10000;
    TotalTotal();
  }

  function handleFansChange(e) {
    var ddl = e.target;
    UpdateFansCost(ddl);
  }

  function UpdateFansCost(ddl) {
    document.getElementById("txtFansTotalCost").value = ddl.value * 10000;
    TotalTotal();
  }

  function handleApothChange(e) {
    var chkbox = e.target;
    UpdateApothCost(chkbox);
  }

  function UpdateApothCost(chkbox) {
    if (chkbox.checked) {
      document.getElementById("txtApothTotalCost").value = 50000;
    } else {
      document.getElementById("txtApothTotalCost").value = "";
    }
    TotalTotal();
  }

  function TotalTotal() {
    const nodeList = document.querySelectorAll("[id*='TotalCost']");

    var sum = 0;
    for (let i = 0; i < nodeList.length; i++) {
      let addend = parseInt(nodeList[i].value);
      if (!isNaN(addend)) {
        sum = sum + addend;
      }
    }

    document.getElementById("txtTotalTotal").value = sum;
    var budget = document.getElementById("txtLeagueBudget").value;
    if (sum > budget) {
      document.getElementById("txtTotalTotal").style.color = "red";
    } else {
      document.getElementById("txtTotalTotal").style.color = "green";
    }
  }

  const RosterTable = () => {
    if (selectedRace) {
      var qty = new Array(6 - selectedTeam.fanFactor + 1).fill(0);
      return (
        <React.Fragment>
          <ReactTable
            className="-striped -highlight"
            columns={positionColumns}
            data={selectedRace.positions.filter((p) => p.quantity > 0)}
            sortable={false}
            showPagination={false}
            defaultPageSize={16}
            minRows={1}
          />
          <br />
          <table className="table table-bordered table-sm table-striped">
            <thead>
              <tr>
                <th style={{ fontWeight: "normal" }}>Team Rerolls & Staff</th>
                <th style={{ fontWeight: "normal", width: "100px" }}>Cost</th>
                <th style={{ fontWeight: "normal", width: "100px" }}>
                  Purchased
                </th>
                <th style={{ fontWeight: "normal", width: "100px" }}>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rerolls</td>
                <td>{selectedRace.rerollCost}</td>
                <td>
                  <select id="ddlRerolls" onChange={handleRerollChange}>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                  </select>
                </td>
                <td style={{ textAlign: "right" }}>
                  <input
                    id="txtRerollTotalCost"
                    type="text"
                    readOnly
                    style={{ width: "90%" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td>Assistant Coaches</td>
                <td>10000</td>
                <td>
                  <select id="ddlCoaches" onChange={handleCoachesChange}>
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                  </select>
                </td>
                <td style={{ textAlign: "right" }}>
                  <input
                    id="txtCoachTotalCost"
                    type="text"
                    readOnly
                    style={{ width: "90%" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td>Cheerleaders</td>
                <td>10000</td>
                <td>
                  <select
                    id="ddlCheerleaders"
                    onChange={handleCheerleadersChange}
                  >
                    <option>0</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                    <option>11</option>
                    <option>12</option>
                  </select>
                </td>
                <td style={{ textAlign: "right" }}>
                  <input
                    id="txtCheerleaderTotalCost"
                    type="text"
                    readOnly
                    style={{ width: "90%" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td>Additional Fans</td>
                <td>10000</td>
                <td>
                  <select id="ddlFans" onChange={handleFansChange}>
                    {qty.map((_, i) => {
                      var iKey = "fans_" + i;
                      return <option key={iKey}>{i}</option>;
                    })}
                  </select>
                </td>
                <td style={{ textAlign: "right" }}>
                  <input
                    id="txtFansTotalCost"
                    type="text"
                    readOnly
                    style={{ width: "90%" }}
                  ></input>
                </td>
              </tr>
              <tr>
                <td>Apothecary</td>
                <td>50000</td>
                <td>
                  <input
                    id="chkApothecary"
                    type="checkbox"
                    onChange={handleApothChange}
                    disabled={selectedRace.apothecary === "No"}
                  />
                </td>
                <td style={{ textAlign: "right" }}>
                  <input
                    id="txtApothTotalCost"
                    type="text"
                    readOnly
                    style={{ width: "90%" }}
                  ></input>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  function handleLeagueChange(e) {
    var budgetBox = document.getElementById("txtLeagueBudget");

    switch (e.target.id) {
      case "rbLeagueGold":
        budgetBox.value = "1500000";
        break;
      case "rbLeagueSilver":
        budgetBox.value = "1400000";
        break;
      case "rbLeagueBronze":
        budgetBox.value = "1300000";
        break;
      default:
        break;
    }
    TotalTotal();
  }

  function handleExtrasChange(e) {
    switch (e.target.id) {
      case "chkGoldChamp":
        document.getElementById("spnNotesGoldChamp").style.display = e.target
          .checked
          ? "block"
          : "none";
        break;
      case "chkFaCupChamp":
        document.getElementById("spnNotesFaCupChamp").style.display = e.target
          .checked
          ? "block"
          : "none";
        break;
      case "chkSmallCupChamp":
        document.getElementById("spnNotesSmallCupChamp").style.display = e
          .target.checked
          ? "block"
          : "none";
        document.getElementById("ddlFans").disabled = e.target.checked;
        if (e.target.checked) {
          var ddl = document.getElementById("ddlFans");
          document.getElementById("ddlFans").selectedIndex = 0;
          UpdateFansCost(ddl);
        }
        break;
      default:
    }
  }

  const ExtrasTable = () => {
    if (selectedRace) {
      return (
        <React.Fragment>
          <table className="table table-bordered table-sm table-striped">
            <thead>
              <tr>
                <th style={{ fontWeight: "normal", width: "300px" }}>Result</th>
                <th style={{ fontWeight: "normal" }}>Notes</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Form.Check
                    inline
                    label="Gold League Champion"
                    id="chkGoldChamp"
                    onChange={handleExtrasChange}
                  ></Form.Check>
                </td>
                <td>
                  <span id="spnNotesGoldChamp" style={{ display: "none" }}>
                    Trophy Reroll
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Check
                    inline
                    label="FA Cup Champion"
                    id="chkFaCupChamp"
                    onChange={handleExtrasChange}
                  ></Form.Check>
                </td>
                <td>
                  <span id="spnNotesFaCupChamp" style={{ display: "none" }}>
                    Trophy Reroll
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <Form.Check
                    inline
                    label="EFL/Bourbon Cup Champion"
                    id="chkSmallCupChamp"
                    onChange={handleExtrasChange}
                  ></Form.Check>
                </td>
                <td>
                  <span id="spnNotesSmallCupChamp" style={{ display: "none" }}>
                    Max Dedicated Fans
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  const BudgetTable = () => {
    if (selectedRace) {
      return (
        <React.Fragment>
          <table className="table table-bordered table-sm table-striped">
            <thead>
              <tr>
                <th style={{ fontWeight: "normal", width: "300px" }}>Tier</th>
                <th style={{ fontWeight: "normal" }}>Source</th>
                <th style={{ fontWeight: "normal", width: "100px" }}>Budget</th>
                <th style={{ fontWeight: "normal", width: "100px" }}>
                  Expenses
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <Form.Check
                    inline
                    label="Gold"
                    name="rbLeague"
                    type="radio"
                    id="rbLeagueGold"
                    onChange={handleLeagueChange}
                  ></Form.Check>
                  <Form.Check
                    inline
                    label="Silver"
                    name="rbLeague"
                    type="radio"
                    id="rbLeagueSilver"
                    onChange={handleLeagueChange}
                  ></Form.Check>
                  <Form.Check
                    inline
                    label="Bronze"
                    name="rbLeague"
                    type="radio"
                    id="rbLeagueBronze"
                    onChange={handleLeagueChange}
                  ></Form.Check>
                </td>
                <td>Tier Next Season</td>
                <td>
                  <input id="txtLeagueBudget" type="text" readOnly></input>
                </td>
                <td>
                  <input id="txtTotalTotal" type="text"></input>
                </td>
              </tr>
            </tbody>
          </table>
          <input type="submit" id="btnSubmit" value="Save Redraft"></input>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  };

  const Error = () => {
    if (error) {
      console.error(error);
    }
    return error == null ? null : (
      <Alert variant="danger">An unexpected error occurred.</Alert>
    );
  };

  const ValidationSummary = () => {
    return validationErrors.length < 1 ? null : (
      <Alert variant="danger">
        <ul>
          {validationErrors.map((e) => (
            <li key={e}>{e}</li>
          ))}
        </ul>
      </Alert>
    );
  };

  const RedraftForm = () => {
    if (teamsData && rostersData) {
      return (
        <Form onSubmit={handleSubmit}>
          <Form.Group controlId="ddlTeam">
            <Form.Control as="select" onChange={handleTeamChange}>
              <option>-- Select Team --</option>
              {teamsData.map((t) => (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <PlayersTable />
          <br />
          <Row>
            <Col>
              <ExtrasTable />
              <BudgetTable />
            </Col>
            <Col>
              <RosterTable />
            </Col>
          </Row>
        </Form>
      );
    } else {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <h1>NWBL Redraft</h1>
      <Error />
      <ValidationSummary />
      <RedraftForm />
    </React.Fragment>
  );
};

export default Redraft;
