import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import SeasonStats from "./Stats/SeasonStats";
import Leaders from "./Stats/Leaders";
import SingleSeasonLeaders from "./Stats/SingleSeasonLeaders";
import Ballot from "./Poll/Ballot";
import Poll from "./Poll/Poll";
import McNurglePoll from "./Poll/McNurglePoll";
import Breakdown from "./Poll/Breakdown";
import McNurgleBreakdown from "./Poll/McNurgleBreakdown";
import McNurgleBallot from "./Poll/McNurgleBallot";
import Recap from "./Poll/Recap";
import BigBoard from "./Stats/BigBoard";
import Layout from "./Layout/Layout";
import Ranking from "./Ranking/Ranking";

import MatadorLayout from "./Layout/MatadorLayout";
import Rules from "./Matador/Rules";
import Info from "./Matador/Info";
import Registration from "./Matador/Registration";
import Venue from "./Matador/Venue";
import GradTool from "./Grads/GradTool";
import Redraft from "./Redraft/Redraft";

const Routes = () => (
  <Switch>
    <RouteWithMatadorLayout path="/MatadorMelee" exact>
      <Redirect to="/MatadorMelee/Info" />
    </RouteWithMatadorLayout>
    <RouteWithMatadorLayout path="/MatadorMelee/Info" exact component={Info} />
    <RouteWithMatadorLayout
      path="/MatadorMelee/Rules"
      exact
      component={Rules}
    />
    <RouteWithMatadorLayout
      path="/MatadorMelee/Registration"
      exact
      component={Registration}
    />
    <RouteWithMatadorLayout
      path="/MatadorMelee/Venue"
      exact
      component={Venue}
    />

    <RouteWithLayout path="/" exact>
      <Redirect to="/fumbbl/NCBB/stats" />
    </RouteWithLayout>
    <RouteWithLayout path="/fumbbl" exact>
      <Redirect to="/fumbbl/NCBB/stats" />
    </RouteWithLayout>
    <Route
      path="/fumbbl/:leagueKey"
      exact
      render={(props) => (
        <Redirect to={`${props.match.params.leagueKey}/stats`} />
      )}
    />
    <RouteWithLayout
      path="/fumbbl/:leagueKey/stats"
      exact
      component={SeasonStats}
    />
    <RouteWithLayout
      path="/fumbbl/:leagueKey/leaders"
      exact
      component={Leaders}
    />
    <RouteWithLayout
      path="/fumbbl/:leagueKey/leadersSingleSeason"
      exact
      component={SingleSeasonLeaders}
    />
    <RouteWithLayout
      path="/fumbbl/:leagueKey/ranking"
      exact
      component={Ranking}
    />
    <Layout>
      <Route path="/fumbbl/NCBB/bigboard" exact component={BigBoard} />
      <Route path="/fumbbl/NCBB/poll/ballot" exact component={Ballot} />
      <Route path="/fumbbl/NCBB/poll/recap" exact component={Recap} />
      <Route
        path="/fumbbl/NCBB/poll/ballot/mcnurgle"
        exact
        component={McNurgleBallot}
      />
      <Route path="/fumbbl/NCBB/poll" exact component={Poll} />
      <Route path="/fumbbl/NCBB/poll/mcnurgle" exact component={McNurglePoll} />
      <Route path="/fumbbl/NCBB/poll/breakdown" exact component={Breakdown} />
      <Route
        path="/fumbbl/NCBB/poll/mcnurgle/breakdown"
        exact
        component={McNurgleBreakdown}
      />
      <Route path="/fumbbl/NCBB/grads" exact component={GradTool} />
      <Route path="/fumbbl/NWBL/redraft" exact component={Redraft} />
    </Layout>
  </Switch>
);

const RouteWithLayout = (props) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

const RouteWithMatadorLayout = (props) => {
  const { component: Component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(matchProps) => (
        <MatadorLayout>
          <Component {...matchProps} />
        </MatadorLayout>
      )}
    />
  );
};

export default Routes;
