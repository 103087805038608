import React from "react";
import StatsTable from "./StatsTable";

const TeamOffenseStats = (props) => {
  const { data } = props;

  data.map((r) => {
    r.calculated = {
      tdPlusMinus:
        r.seasonStatistics.touchdowns - r.seasonStatistics.touchdownsAgainst,
      totalYards: r.seasonStatistics.rushing + r.seasonStatistics.passing,
      rushingPerGame: r.seasonStatistics.rushing / r.seasonStatistics.games,
      passingPerGame: r.seasonStatistics.passing / r.seasonStatistics.games,
      totalYardsPerGame:
        (r.seasonStatistics.rushing + r.seasonStatistics.passing) /
        r.seasonStatistics.games,
    };
    return r;
  });

  const columns = [
    { Header: "", accessor: "team.id", show: false },
    {
      Header: "Name",
      accessor: "team.name",
      fixed: "left",
      minWidth: 250,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/team?team_id=${row.original.team.id}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Race",
      accessor: "team.roster.name",
      filterable: true,
      sortable: false,
      minWidth: 150,
    },
    {
      Header: "TD",
      accessor: "seasonStatistics.touchdowns",
      className: "txt-rt",
    },
    {
      Header: "TD Against",
      accessor: "seasonStatistics.touchdownsAgainst",
      className: "txt-rt",
      defaultSortDesc: false,
    },
    {
      Header: "TD +/-",
      accessor: "calculated.tdPlusMinus",
      className: "txt-rt",
    },

    {
      Header: "Cp",
      accessor: "seasonStatistics.completions",
      className: "txt-rt",
    },
    {
      Header: "Rushing",
      accessor: "seasonStatistics.rushing",
      className: "txt-rt",
    },
    {
      Header: "Passing",
      accessor: "seasonStatistics.passing",
      className: "txt-rt",
    },

    {
      Header: "Total Yd",
      accessor: "calculated.totalYards",
      className: "txt-rt",
    },
    {
      Header: "Rushing PG",
      accessor: "calculated.rushingPerGame",
      className: "txt-rt",
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "Passing PG",
      accessor: "calculated.passingPerGame",
      className: "txt-rt",
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "Total Yd PG",
      accessor: "calculated.totalYardsPerGame",
      className: "txt-rt",
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "Games",
      accessor: "seasonStatistics.games",
      className: "txt-rt",
    },
  ];

  return (
    <StatsTable
      columns={columns}
      data={data}
      defaultSorted={[{ desc: true, id: "seasonStatistics.touchdowns" }]}
    />
  );
};

export default TeamOffenseStats;
