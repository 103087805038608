import React from "react";
import StatsTable from "./StatsTable";

const StarPlayerStats = (props) => {
  const { data } = props;

  const columns = [
    {
      Header: "",
      accessor: "player.id",
      show: false,
    },
    {
      Header: "",
      accessor: "player.teamId",
      show: false,
    },
    {
      Header: "Name",
      accessor: "player.name",
      fixed: "left",
      minWidth: 200,
      filterable: true,
      sortable: false,
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/player?player_id=${row.original.player.id}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Team",
      accessor: "team.name",
      minWidth: 250,
      filterable: true,
      sortable: false,

      fixed: "left",
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/team?team_id=${row.original.player.teamId}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Position",
      accessor: "player.position.name",
      minWidth: 150,
      filterable: true,
      sortable: false,
    },
    {
      Header: "Race",
      accessor: "team.roster.name",
      filterable: true,
      sortable: false,

      minWidth: 150,
    },
    // TODO: Conference
    { Header: "Status", accessor: "player.status", filterable: true },
    {
      Header: "Career SPP",
      accessor: "player.statistics.spp",
      className: "txt-rt",
    },
    {
      Header: "Season SPP",
      accessor: "seasonStatistics.spp",
      className: "txt-rt",
    },
    {
      Header: "TD",
      accessor: "seasonStatistics.touchdowns",
      className: "txt-rt",
    },
    {
      Header: "Cas",
      accessor: "seasonStatistics.casualties",
      className: "txt-rt",
    },
    {
      Header: "Cp",
      accessor: "seasonStatistics.completions",
      className: "txt-rt",
    },
    {
      Header: "Int",
      accessor: "seasonStatistics.interceptions",
      className: "txt-rt",
    },
    { Header: "MVP", accessor: "seasonStatistics.mvp", className: "txt-rt" },
    {
      Header: "Games",
      accessor: "seasonStatistics.games",
      className: "txt-rt",
    },
    {
      Header: "Turns",
      accessor: "seasonStatistics.turns",
      className: "txt-rt",
    },
  ];

  return (
    <StatsTable
      columns={columns}
      data={data}
      defaultSorted={[{ desc: true, id: "seasonStatistics.spp" }]}
    />
  );
};

export default StarPlayerStats;
