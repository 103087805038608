import React from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";

const StatsTable = (props) => {
  const { columns, data, defaultSorted } = props;
  const ReactTableFixedColumns = withFixedColumns(ReactTable);

  return data ? (
    <ReactTableFixedColumns
      className="-striped -highlight"
      columns={columns}
      data={data}
      showPaginationTop={true}
      filterable={false}
      defaultSortDesc={true}
      defaultSorted={defaultSorted}
      pageSizeOptions={[15, 30, 45, 60, 90]}
      defaultPageSize={15}
      defaultFilterMethod={(filter, row, column) => {
        const filterValue = filter.value.toLowerCase();
        const id = filter.pivotId || filter.id;
        return row[id] !== undefined
          ? String(row[id]).toLowerCase().includes(filterValue)
          : true;
      }}
    />
  ) : null;
};

export default StatsTable;
