import React, { useState } from "react";
import { Alert, Button, Form, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import pinList from "./Data/pinList";

const FormContainer = styled.div`
  border: 1px solid darkgrey;
  border-radius: 3px;
  padding: 16px;
`;

const SignInForm = (props) => {
  const { coaches, show, handleSignIn, spinner } = props;

  const [showAlert, setShowAlert] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showValidated, setShowValidated] = useState(false);

  function validatePin(e) {
    e.preventDefault();
    const form = e.target;
    const data = form.elements;
    const coachName = data.coach.value;
    const pin = data.pin.value;
    const truePin = pinList.find((c) => c.name === coachName).pin;

    if (pin === truePin) {
      setShowAlert(false);
      setShowValidated(true);
      setShowForm(false);
      handleSignIn(coachName);
    } else {
      setShowAlert(true);
    }
  }

  const InvalidPin = () => {
    return showAlert ? (
      <Alert variant="danger">You have entered an incorrect PIN</Alert>
    ) : null;
  };

  const TheForm = (props) =>
    !props.show ? null : (
      <Form onSubmit={validatePin}>
        <div>
          <InvalidPin />
          <Form.Group controlId="coach">
            <Form.Label>Fumbbl Coach Name</Form.Label>
            <Form.Control as="select">
              {coaches.map((c) => (
                <option key={c} value={c}>
                  {c}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          <Form.Group controlId="pin">
            <Form.Label>PIN (case-sensitive, use CAPS)</Form.Label>
            <Form.Control type="password" />
          </Form.Group>
          <Button className="btn-primary" type="submit">
            Validate
          </Button>
        </div>
      </Form>
    );

  const Validated = (props) =>
    !props.show ? null : <div>PIN Validated, loading ballot...</div>;

  if (!show) return null;

  const FormBody = () => {
    return spinner ? (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    ) : (
      <React.Fragment>
        <TheForm show={showForm} />
        <Validated show={showValidated} />
      </React.Fragment>
    );
  };

  return (
    <Row>
      <Col />
      <Col>
        <FormContainer>
          <h2>Register Ballot</h2>
          <FormBody />
        </FormContainer>
      </Col>
      <Col />
    </Row>
  );
};

export default SignInForm;
