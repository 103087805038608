import React, { useEffect } from "react";
import { Navbar, Nav, NavDropdown } from "react-bootstrap";
import styled from "styled-components";
import "../../matador.css";

const ContactDropdown = styled(NavDropdown)`
  .nav-link {
    color: #ffffff !important;
  }
`;

const MatadorLayout = (props) => {
  useEffect(() => {
    document.title = "Matador Melee";
    var link = document.querySelector("link[rel~='icon']");
    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }
    link.href = "/matador2_diag.png";

    if (window.innerWidth <= 900)
      document.getElementById("navTop").classList.remove("navbar-expand");

    window.addEventListener("resize", () => {
      window.innerWidth <= 900
        ? document.getElementById("navTop").classList.remove("navbar-expand")
        : document.getElementById("navTop").classList.add("navbar-expand");
    });
  });

  return (
    <React.Fragment>
      <Navbar id="navTop" className="sticky-top matadorNav">
        <Navbar.Brand href="/MatadorMelee" className="matadorBrand">
          Matador Melee
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Nav.Link className="white" href="/MatadorMelee/Info">
              Info
            </Nav.Link>
            <Nav.Link className="white" href="/MatadorMelee/Rules">
              Rules
            </Nav.Link>
            <Nav.Link className="white" href="/MatadorMelee/Registration">
              Registration
            </Nav.Link>
            <Nav.Link className="white" href="/MatadorMelee/Venue">
              Venue
            </Nav.Link>
            <ContactDropdown title="Contact">
              <NavDropdown.Item href="mailto:matadormelee@gmail.com">
                matadormelee@gmail.com
              </NavDropdown.Item>
            </ContactDropdown>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
      <div className="jumbotron text-center">
        <div className="container"></div>
        <h1 className="display-1 text-bold">Matador Melee V</h1>
        <h3 className="text-muted">
          The Blood Bowl tournament where the fans matter!
        </h3>
      </div>
      <div className="container">{props.children}</div>
    </React.Fragment>
  );
};

export default MatadorLayout;
