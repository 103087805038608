import React, { useEffect, useState } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import axios from "axios";
import { Button, Dropdown, DropdownButton, Form } from "react-bootstrap";
// import leagueSeasons from "../Stats/Data/leagueSeasons";

const GradTool = () => {
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [statsData, setStatsData] = useState(null);
  const [teamPlayerData, setTeamPlayerData] = useState(null);
  const [teamsSorted, setTeamsSorted] = useState(null);
  const [selectedTeamId, setSelectedTeamId] = useState(0);
  const [seasonDraftPool, setSeasonDraftPool] = useState(null);
  const [teamDraftPool, setTeamDraftPool] = useState(null);
  // const [selectedSeasonId, setSelectedSeasonId] = useState(0);
  // const [ncbbSeasons, setNcbbSeasons] = useState(null);

  // useEffect(() => {
  //   setNcbbSeasons(leagueSeasons.filter(s => s.leagueKey === "NCBB")[0].seasons.sort((a,b) => b.seasonKey - a.seasonKey));
  // }, []);

  // useEffect(() => {
  //   if(ncbbSeasons) {
  //     setSelectedSeasonId(ncbbSeasons[0].seasonId);
  //   }
  // }, [ncbbSeasons])

  const selectedSeasonId = "ncbb-season-44";

  useEffect(() => {
    console.log("useEffect - GetSeasonStats");
    axios
      .get(`/api/seasonStats?seasonId=${selectedSeasonId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setIsDataLoaded(true);
        setStatsData(result.data);
      });

    console.log("useEffect - GetDraftPool");
    axios
      .get(`/api/draftPool?seasonId=${selectedSeasonId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setSeasonDraftPool(result.data[0]);
        console.log("draftPool", result.data[0]);
      });
  }, [selectedSeasonId]);

  useEffect(() => {
    if (isDataLoaded && statsData) {
      console.log("useEffect - SetTeamsSorted");
      var teamSet = statsData
        .map((s) => s.team)
        .filter(function ({ id }) {
          return !this.has(id) && this.add(id);
        }, new Set());

      var teams = Array.from(teamSet);

      teams.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        else return 0;
      });

      setTeamsSorted(teams);
    }
  }, [isDataLoaded, statsData]);

  useEffect(() => {
    if (selectedTeamId) {
      console.log("useEffect - setTeamPlayerData");
      var players = statsData
        .filter(
          (s) => s.team.id === selectedTeamId && s.player.status !== "Dead"
        )
        .sort((a, b) => {
          if (a.player.statistics.games > b.player.statistics.games) return -1;
          if (a.player.statistics.games < b.player.statistics.games) return 1;
          return 0;
        });

      setTeamPlayerData(players);

      var sdp = seasonDraftPool
        ? seasonDraftPool.teams.filter((p) => p.teamId === selectedTeamId)[0]
        : null;
      setTeamDraftPool(sdp);
      console.log("pool", players);
    }
  }, [selectedTeamId, statsData, seasonDraftPool]);

  const columns = [
    {
      Header: "Name",
      accessor: "player.name",
      width: 300,
      Cell: (row) => (
        <React.Fragment>
          <a
            target="_blank"
            rel="noreferrer noopener"
            href={`https://fumbbl.com/p/player?player_id=${row.original.player.id}`}
          >
            {row.original.player.name}
          </a>
        </React.Fragment>
      ),
    },
    {
      Header: "Position",
      accessor: "player.position.name",
      width: 175,
    },
    {
      Header: "Status",
      accessor: "player.status",
      width: 75,
    },
    {
      Header: "Injuries",
      accessor: "player.injuries",
      className: "txt-ctr",
      Cell: (row) => (
        <React.Fragment>
          {row.original.player.injuries
            .filter((i) => i !== "Missing Next Game" && !i.includes("MNG"))
            .join(", ")}
        </React.Fragment>
      ),
    },
    {
      Header: "Games",
      accessor: "player.statistics.games",
      width: 75,
      className: "txt-rt",
    },
    {
      Header: "Add to Draft",
      accessor: "player.id",
      className: "txt-ctr",
      width: 125,
      Cell: (row) => (
        <Form.Check
          type="checkbox"
          name="chkPlayers"
          id={"chk_" + row.original.player.id}
          defaultChecked={
            teamDraftPool &&
            teamDraftPool.playerIds.includes(row.original.player.id)
          }
        />
      ),
    },
  ];

  const TeamGradTable = () => {
    if (isDataLoaded && teamPlayerData) {
      return (
        <ReactTable
          className="-striped -highlight"
          columns={columns}
          data={teamPlayerData}
          sortable={false}
          showPagination={false}
          defaultPageSize={500}
          minRows={1}
        />
      );
    } else if (selectedTeamId) {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  // function onSeasonChange(e)
  // {
  //   setSelectedSeasonId(e.target.value);
  //   setSelectedTeamId(0);
  //   setTeamPlayerData(null);
  //   setIsDataLoaded(false);
  // }

  function handleTeamChange(e) {
    setSelectedTeamId(parseInt(e));
  }

  // const SeasonsDropDown = () => {
  //   if (isDataLoaded && ncbbSeasons)
  //   {
  //     return (
  //       <select value={selectedSeasonId} onChange={onSeasonChange}>
  //         {ncbbSeasons.map(s => (
  //           <option key={s.seasonId} value={s.seasonId}>Season {s.seasonKey}</option>
  //         ))}
  //       </select>
  //     );
  //   }
  //   else {
  //     return (
  //       <div className="text-center">
  //         <div className="spinner-border" role="status">
  //           <span className="sr-only">Loading...</span>
  //         </div>
  //       </div>
  //     )
  //   };
  // }

  function handleSubmit(e) {
    e.preventDefault();
    const allChecked = Array.from(
      document.querySelectorAll("input[name=chkPlayers]:checked")
    );

    const checkedIds = allChecked.map((c) => Number(c.id.substring(4)));
    console.log("saveChecks", checkedIds);

    var newDraftPool = seasonDraftPool;

    if (
      newDraftPool.teams.filter((t) => t.teamId === selectedTeamId).length === 0
    ) {
      console.log("addTeam to Draft pool");
      newDraftPool.teams.push({
        teamId: selectedTeamId,
        playerIds: checkedIds,
      });
    } else {
      console.log("modify player ids");
      newDraftPool.teams.filter(
        (t) => t.teamId === selectedTeamId
      )[0].playerIds = checkedIds;
    }

    console.log("newSeasonDraftPool", newDraftPool);

    axios.put(`/api/draftPool/${newDraftPool.id}`, newDraftPool).then(
      (response) => {
        setSeasonDraftPool(newDraftPool);
        alert("saved");
      },
      (error) => {
        alert("error");
      }
    );
  }

  const TeamsDropDown = () => {
    if (
      isDataLoaded &&
      selectedSeasonId &&
      teamsSorted &&
      teamsSorted.length > 0
    ) {
      return (
        <DropdownButton
          onSelect={handleTeamChange}
          title="Select Team"
          className="ht500"
        >
          {teamsSorted.map((t) => (
            <Dropdown.Item key={t.id} eventKey={t.id}>
              {t.name}
            </Dropdown.Item>
          ))}
        </DropdownButton>
      );
    }

    return (
      <div className="text-center">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <h1>
        NCBB Graduates
        {selectedTeamId > "0" &&
          " - ".concat(
            teamsSorted.filter((t) => t.id === selectedTeamId)[0].name
          )}
      </h1>
      {/* <SeasonsDropDown/> */}
      <div>
        <TeamsDropDown />
      </div>
      <br />
      <Form onSubmit={handleSubmit}>
        <TeamGradTable />
        {teamPlayerData && (
          <>
            <br />
            <Button type="submit">Save Team Draft Pool</Button>
          </>
        )}
      </Form>
    </React.Fragment>
  );
};

export default GradTool;
