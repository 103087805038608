import React, { useEffect, useState } from "react";
import StarPlayerStats from "./StarPlayerStats";
import HittingStats from "./HittingStats";
import OffenseStats from "./OffenseStats";
import TeamStarPlayerStats from "./TeamStarPlayerStats";
import TeamHittingStats from "./TeamHittingStats";
import TeamOffenseStats from "./TeamOffenseStats";
import { Link } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";
import leagueSeasons from "./Data/leagueSeasons";
import { Dropdown, DropdownButton } from "react-bootstrap";

const StatCatNav = styled.li`
  padding: 8px;
  border: 1px solid darkgrey;
  border-radius: 3px;
  margin-right: 8px;
  background-color: lightgrey;
  color: black;
`;

const SeasonStats = (props) => {
  const leagueKey = props.match.params.leagueKey;
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const statsCategory = params.get("cat");
  const selectedSeasonKey = params.get("season");

  const currentSeason = leagueSeasons
    .find((s) => s.leagueKey === leagueKey)
    .seasons.find((s) => s.current === true);

  const seasonId = selectedSeasonKey
    ? `${leagueKey.toLowerCase()}-season-${selectedSeasonKey}`
    : currentSeason.seasonId;

  const selectedSeason =
    leagueSeasons
      .find((s) => s.leagueKey === leagueKey)
      .seasons.find((s) => s.seasonKey === selectedSeasonKey) ?? currentSeason;

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [statsData, setStatsData] = useState(null);
  const [seasonKey, setSeasonKey] = useState(null);
  const [lastCat, setLastCat] = useState(null);

  useEffect(() => {
    if (
      lastCat !== "team" &&
      (statsCategory === "teamStar" ||
        statsCategory === "teamHitting" ||
        statsCategory === "teamOffense")
    ) {
      const key = seasonId.split("-").pop();
      setSeasonKey(key);
      setLastCat("team");
      axios
        .get(`/api/teamSeasonStats?seasonId=${seasonId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((result) => {
          setIsDataLoaded(true);
          setStatsData(result.data);
        });
    } else if (
      lastCat !== "player" &&
      statsCategory !== "teamStar" &&
      statsCategory !== "teamHitting" &&
      statsCategory !== "teamOffense"
    ) {
      const key = seasonId.split("-").pop();
      setSeasonKey(key);
      setLastCat("player");
      axios
        .get(`/api/seasonStats?seasonId=${seasonId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((result) => {
          setIsDataLoaded(true);
          setStatsData(result.data);
        });
    }
  }, [seasonId, statsCategory, lastCat]);

  const StatsCatTable = () => {
    if (isDataLoaded && statsData) {
      switch (statsCategory) {
        case "hitting":
          return <HittingStats data={statsData} />;
        case "offense":
          return <OffenseStats data={statsData} />;
        case "teamStar":
          return <TeamStarPlayerStats data={statsData} />;
        case "teamHitting":
          return <TeamHittingStats data={statsData} />;
        case "teamOffense":
          return <TeamOffenseStats data={statsData} />;
        default:
          return <StarPlayerStats data={statsData} />;
      }
    } else {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
  };

  const StatCatLinks = () => {
    const statsCatQueryString =
      statsCategory != null ? `&cat=${statsCategory}` : "";
    if (statsCategory && statsCategory.startsWith("team")) {
      return (
        <ul className="nav mb-2">
          <a href={`/fumbbl/${leagueKey}/stats?season=${seasonKey}`}>
            <StatCatNav
              className={`nav-item ${statsCategory === null ? "active" : ""}`}
            >
              Star Player Stats
            </StatCatNav>
          </a>
          <a
            href={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=hitting`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "hitting" ? "active" : ""
              }`}
            >
              Hitting Stats
            </StatCatNav>
          </a>
          <a
            href={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=offense`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "offense" ? "active" : ""
              }`}
            >
              Offense Stats
            </StatCatNav>
          </a>
          <Link
            to={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=teamStar`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "teamStar" ? "active" : ""
              }`}
            >
              Team Star Player Stats
            </StatCatNav>
          </Link>
          <Link
            to={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=teamHitting`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "teamHitting" ? "active" : ""
              }`}
            >
              Team Hitting Stats
            </StatCatNav>
          </Link>
          <Link
            to={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=teamOffense`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "teamOffense" ? "active" : ""
              }`}
            >
              Team Offense Stats
            </StatCatNav>
          </Link>
          <li>
            <DropdownButton
              title={selectedSeason.display ?? `Season ${seasonKey}`}
              className="seasonDropdownButton"
            >
              {leagueSeasons
                .find((l) => l.leagueKey === leagueKey)
                .seasons.sort((a, b) => b.seasonKey - a.seasonKey)
                .map((s) => (
                  <Dropdown.Item
                    key={s.seasonKey}
                    href={`/fumbbl/${leagueKey}/stats?season=${s.seasonKey}${statsCatQueryString}`}
                  >
                    {s.display ? s.display : `Season ${s.seasonKey}`}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
          </li>
        </ul>
      );
    } else {
      return (
        <ul className="nav mb-2">
          <Link to={`/fumbbl/${leagueKey}/stats?season=${seasonKey}`}>
            <StatCatNav
              className={`nav-item ${statsCategory === null ? "active" : ""}`}
            >
              Star Player Stats
            </StatCatNav>
          </Link>
          <Link
            to={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=hitting`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "hitting" ? "active" : ""
              }`}
            >
              Hitting Stats
            </StatCatNav>
          </Link>
          <Link
            to={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=offense`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "offense" ? "active" : ""
              }`}
            >
              Offense Stats
            </StatCatNav>
          </Link>
          <a
            href={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=teamStar`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "teamStar" ? "active" : ""
              }`}
            >
              Team Star Player Stats
            </StatCatNav>
          </a>
          <a
            href={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=teamHitting`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "teamHitting" ? "active" : ""
              }`}
            >
              Team Hitting Stats
            </StatCatNav>
          </a>
          <a
            href={`/fumbbl/${leagueKey}/stats?season=${seasonKey}&cat=teamOffense`}
          >
            <StatCatNav
              className={`nav-item ${
                statsCategory === "teamOffense" ? "active" : ""
              }`}
            >
              Team Offense Stats
            </StatCatNav>
          </a>
          <li>
            <DropdownButton
              title={selectedSeason.display ?? `Season ${seasonKey}`}
              className="seasonDropdownButton"
            >
              {leagueSeasons
                .find((l) => l.leagueKey === leagueKey)
                .seasons.sort((a, b) => b.seasonKey - a.seasonKey)
                .map((s) => (
                  <Dropdown.Item
                    key={s.seasonKey}
                    href={`/fumbbl/${leagueKey}/stats?season=${s.seasonKey}${statsCatQueryString}`}
                  >
                    {s.display ? s.display : `Season ${s.seasonKey}`}
                  </Dropdown.Item>
                ))}
            </DropdownButton>
          </li>
        </ul>
      );
    }
  };

  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center" }}>
        {selectedSeason.display ??
          `${leagueKey} Season ${isDataLoaded ? seasonKey : ""}`}
        &nbsp;Stats
      </h2>
      <StatCatLinks />
      <StatsCatTable />
    </React.Fragment>
  );
};

export default SeasonStats;
