import React, { useEffect, useState } from "react";
import { Alert, Row, Col } from "react-bootstrap";
import { DragDropContext } from "react-beautiful-dnd";
import ballotData from "./Data/ballotData";
import mcNurgleData from "./Data/mcNurgleData";
import PollColumn from "./PollColumn";
import DetailsColumn from "./DetailsColumn";
import SignInForm from "./SignInForm";
import axios from "axios";
import { useHistory } from "react-router-dom";
import pinList from "./Data/pinList";

const McNurgleBallot = () => {
  const [pollBallots, setPollBallots] = useState(null);
  const [isPollBallotsLoaded, setIsPollBallotsLoaded] = useState(false);

  const [coachName, setCoachName] = useState(null);
  const [showSignIn, setShowSignIn] = useState(true);

  const [ballotObjData, setBallotObjData] = useState(null);

  const [ballotSaved, setBallotSaved] = useState(false);
  const [error, setError] = useState(null);

  const [teamData, setTeamData] = useState(null);
  const [isTeamDataLoaded, setIsTeamDataLoaded] = useState(false);

  const [coaches, setCoaches] = useState([]);

  let history = useHistory();

  useEffect(() => {
    axios
      .get(`/api/ballot?pollId=${mcNurgleData.currentPoll.id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setIsPollBallotsLoaded(true);
        setPollBallots(result.data);
      });

    axios
      .get(`/api/teamDetails?seasonId=ncbb-season-45`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setTeamData(result.data);
        setIsTeamDataLoaded(true);
      });

    var coachData = pinList
      .map((p) => p.name)
      .sort((a, b) => {
        if (a.toLowerCase() > b.toLowerCase()) return 1;
        if (a.toLowerCase() < b.toLowerCase()) return -1;
        return 0;
      });
    setCoaches(coachData);
  }, []);

  useEffect(() => {
    if (coachName != null) {
      setShowSignIn(false);
    }
  }, [coachName]);

  useEffect(() => {
    if (isPollBallotsLoaded && isTeamDataLoaded && coachName !== null) {
      const myBallot = pollBallots.find((b) => b.coachName === coachName);

      teamData
        .filter(
          (t) =>
            !t.IsBowlEligible && !t.IsNonAq && t.wins + t.losses + t.draws > 5
        )
        .sort((a, b) => {
          if (a.points < b.points) return -1;
          if (a.points > b.points) return 1;
          return 0;
        })
        .forEach((td, index) => {
          const stringId = td.team.id.toString();
          ballotData.teams[stringId] = { ...td, id: stringId };
          ballotData.pollColumns["all-teams"].teamIds.splice(
            index,
            0,
            stringId
          );
        });

      if (myBallot != null) {
        const ballotTeamIds = myBallot.ballot.map((b) => b.teamId);
        const allTeamIds = ballotData.pollColumns["all-teams"].teamIds.filter(
          (i) => !ballotTeamIds.includes(i)
        );

        const myBallotColumn = ballotData.pollColumns["my-ballot"];
        const allTeamsColumn = ballotData.pollColumns["all-teams"];
        const existingBallotData = {
          ...ballotData,
          id: myBallot.id,
          pollColumns: {
            "my-ballot": {
              ...myBallotColumn,
              teamIds: ballotTeamIds,
            },
            "all-teams": {
              ...allTeamsColumn,
              teamIds: allTeamIds,
            },
          },
        };

        setBallotObjData(existingBallotData);
      } else {
        setBallotObjData(ballotData);
      }
    }
  }, [isPollBallotsLoaded, isTeamDataLoaded, teamData, coachName, pollBallots]);

  useEffect(() => {
    if (ballotSaved) {
      alert("Ballot saved!");
      history.push("/fumbbl/NCBB/poll/mcnurgle");
    }
  }, [ballotSaved, history]);

  function handleDragEnd(result) {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (
      destination.droppableId === "compare-left" ||
      destination.droppableId === "compare-right"
    ) {
      // view details
      const column = ballotObjData.detailColumns[destination.droppableId];
      const teamDetail = ballotObjData.teams[draggableId];

      const newColumn = {
        ...column,
        teamDetail: teamDetail,
      };

      const newballotObjData = {
        ...ballotObjData,
        detailColumns: {
          ...ballotObjData.detailColumns,
          [newColumn.id]: newColumn,
        },
      };

      setBallotObjData(newballotObjData);
    } else if (destination.droppableId === source.droppableId) {
      // same column
      const column = ballotObjData.pollColumns[source.droppableId];
      const newTeamIds = Array.from(column.teamIds);
      newTeamIds.splice(source.index, 1);
      newTeamIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...column,
        teamIds: newTeamIds,
      };

      const newballotObjData = {
        ...ballotObjData,
        pollColumns: {
          ...ballotObjData.pollColumns,
          [newColumn.id]: newColumn,
        },
      };

      setBallotObjData(newballotObjData);
    } else {
      // between poll columns
      const sourceColumn = ballotObjData.pollColumns[source.droppableId];
      const destColumn = ballotObjData.pollColumns[destination.droppableId];
      const newSourceTeamIds = Array.from(sourceColumn.teamIds);
      const newDestTeamIds = Array.from(destColumn.teamIds);

      if (destColumn.id === "my-ballot" && destColumn.teamIds.length >= 4) {
        alert(
          "Only four teams may be selected.  Remove a team from your ballot before before adding another."
        );
        return;
      }

      newSourceTeamIds.splice(source.index, 1);
      newDestTeamIds.splice(destination.index, 0, draggableId);

      const newSourceColumn = {
        ...sourceColumn,
        teamIds: newSourceTeamIds,
      };

      const newDestColumn = {
        ...destColumn,
        teamIds: newDestTeamIds,
      };

      const newballotObjData = {
        ...ballotObjData,
        pollColumns: {
          [newSourceColumn.id]: newSourceColumn,
          [newDestColumn.id]: newDestColumn,
        },
      };

      setBallotObjData(newballotObjData);
    }
  }

  function handleSignIn(coachName) {
    setCoachName(coachName);
    setShowSignIn(false);
  }

  function handleSubmit() {
    const myRanking = ballotObjData.pollColumns["my-ballot"].teamIds.map(
      (i, index) => {
        const item = {
          teamId: i,
          name: ballotData.teams[i].team.name,
          rank: 1,
        };
        return item;
      }
    );

    if (myRanking.length !== 4) {
      alert("Please rank exactly four teams");
      return;
    }

    if (ballotObjData.id == null) {
      const newBallot = {
        coachName: coachName,
        pollId: mcNurgleData.currentPoll.id,
        ballot: myRanking,
      };

      axios.post("/api/ballot", newBallot).then(
        (response) => {
          setBallotSaved(true);
        },
        (error) => setError(error)
      );
    } else {
      const newBallot = {
        id: ballotObjData.id,
        coachName: coachName,
        pollId: mcNurgleData.currentPoll.id,
        ballot: myRanking,
      };

      axios.put(`/api/ballot/${ballotObjData.id}`, newBallot).then(
        (response) => {
          setBallotSaved(true);
        },
        (error) => setError(error)
      );
    }
  }

  const BallotColumns = () => {
    if (ballotObjData == null) {
      return showSignIn ? null : (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h2 className="text-center">McNurgle's Poll</h2>
          </Col>
        </Row>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Row>
            {ballotObjData.pollColumnOrder.map((columnId, index) => {
              const column = ballotObjData.pollColumns[columnId];
              const teamDetails = column.teamIds.map(
                (teamId) => ballotObjData.teams[teamId]
              );
              const onSubmit = columnId === "my-ballot" ? handleSubmit : null;

              return (
                <PollColumn
                  key={column.id}
                  column={column}
                  teamDetails={teamDetails}
                  showRank={false}
                  onSubmit={onSubmit}
                />
              );
            })}

            {ballotObjData.detailColumnOrder.map((columnId, index) => {
              const column = ballotObjData.detailColumns[columnId];
              const teamDetail = column.teamDetail;

              return (
                <DetailsColumn
                  key={column.id}
                  column={column}
                  teamDetail={teamDetail}
                />
              );
            })}
          </Row>
        </DragDropContext>
      </React.Fragment>
    );
  };

  const Error = () => {
    if (error) {
      console.error(error);
    }
    return error == null ? null : (
      <Alert variant="danger">An unexpected error occurred.</Alert>
    );
  };

  if (mcNurgleData.currentPoll.id == null) {
    return (
      <h3 className="txt-ctr">
        The McNurgles Poll is currently closed. You will receive a FUMBBL PM
        when the voting period begins.
      </h3>
    );
  } else {
    return (
      <React.Fragment>
        <Error />
        <SignInForm
          coaches={coaches}
          show={showSignIn}
          spinner={!isTeamDataLoaded || !isPollBallotsLoaded}
          handleSignIn={handleSignIn}
        />
        <BallotColumns />
      </React.Fragment>
    );
  }
};

export default McNurgleBallot;
