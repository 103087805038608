import axios from "axios";
import React, { useEffect, useState } from "react";
import leagueSeasons from "../Stats/Data/leagueSeasons";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import { Row, Col } from "react-bootstrap";

const Ranking = (props) => {
  const leagueKey = props.match.params.leagueKey;

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [rankingData, setRankingData] = useState(null);

  const currentSeason = leagueSeasons
    .find((s) => s.leagueKey === leagueKey)
    .seasons.find((s) => s.rankings === true);

  const seasonKey = currentSeason.seasonKey;
  const seasonId = `${leagueKey.toLowerCase()}-season-${seasonKey}`;

  useEffect(() => {
    axios
      .get(`/api/seasonRanking?seasonId=${seasonId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        var rankData = result.data
          .filter((d) => d.score !== "NaN")
          .sort((a, b) => {
            if (a.score > b.score) return -1;
            if (a.score < b.score) return 1;
            return 0;
          });
        var sosData = rankData.slice().sort((a, b) => {
          if (a.sos > b.sos) return -1;
          if (a.sos < b.sos) return 1;
          return 0;
        });

        var lemur = rankData.map((r) => r.score);
        var sos = sosData.map((s) => s.sos);

        rankData.forEach((r) => (r.scoreRank = lemur.indexOf(r.score) + 1));
        rankData.forEach((r) => (r.sosRank = sos.indexOf(r.sos) + 1));

        setRankingData(rankData);
        // setSosRankingData(sosData);
        setIsDataLoaded(true);
      });
  }, [seasonId]);

  const columns = [
    {
      Header: "Rank",
      accessor: "scoreRank",
      // Cell: (row) => row.index + 1,
      width: 75,
    },
    {
      Header: "Team",
      accessor: "teamName",
      Cell: (row) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`https://fumbbl.com/p/team?team_id=${row.original.teamId}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Score",
      accessor: "score",
      Cell: (row) => {
        return row.value.toFixed(4);
      },
      className: "txt-rt",
      width: 75,
    },
    {
      Header: "Record",
      accessor: "record",
      className: "txt-rt",
      width: 75,
    },
    {
      Header: "SoS",
      accessor: "sos",
      Cell: (row) => {
        return row.value.toFixed(4);
      },
      className: "txt-rt",
      width: 75,
    },
    {
      Header: "SoS Rank",
      accessor: "sosRank",
      // Cell: (row) => {
      //   return (
      //     sosRankingData.indexOf(
      //       sosRankingData.find((d) => d.teamId === row.original.teamId)
      //     ) + 1
      //   );
      // },
      className: "txt-rt",
      width: 100,
    },
  ];

  const RankingsTable = () => {
    if (isDataLoaded) {
      console.log(rankingData);
      return (
        <ReactTable
          className="-striped -highlight"
          columns={columns}
          data={rankingData}
          sortable={false}
          showPagination={false}
          style={{ clear: "both" }}
          defaultPageSize={500}
          minRows={1}
        />
      );
    } else {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col className="col-xl-6 col-lg-12 m-auto">
          <div>
            <h2 className="float-left">{`HyperboLemuR Rankings - Season ${seasonKey}`}</h2>
          </div>
          <RankingsTable />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Ranking;
