import React from "react";
import { Row, Col } from "react-bootstrap";

const Registration = () => (
  <Row>
    <Col style={{ fontSize: "1.5rem" }}>
      <h1>Registration</h1>
      <p>
        Stormcrow Games now partners with Best Coast Pairings to receive
        tournament registrations. If you do not have a Best Coast Pairings
        account, please visit{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://web.bestcoastpairings.com/login.php"
        >
          Best Coast Pairings
        </a>{" "}
        and click Sign Up at the bottom of the screen to create one before
        registering.
      </p>
      <p>
        To register and pay to claim your spot, please click{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.bestcoastpairings.com/event/PHP5WT92U4"
        >
          here
        </a>
      </p>
      <p>$30 – Pre-register by September 14th</p>
      <h1>Rosters</h1>
      <p>
        Please send your rosters to matadormelee@gmail.com before September
        14th.
      </p>
    </Col>
  </Row>
);

export default Registration;
