import React from "react";
import { Popover, OverlayTrigger } from "react-bootstrap";
import styled from "styled-components";
import StatlineTable from "../Stats/StatlineTable";
import nonAqTeamIds from "./Data/nonAqTeamIds";

const ResultRow = styled.tr`
  color: ${(props) => {
    if (props.result === "W") return "green";
    if (props.result === "L") return "red";
    return "black";
  }};
`;
const RecordCell = styled.td`
  white-space: nowrap;
  padding: 0.5rem !important;
`;
const DetailsTable = styled.table`
  font-size: 14px;
`;
const SubHeader = styled.p`
  font-weight: bold;
  margin: 0;
`;
const Tiny = styled.span`
  font-size: 0.7rem;
  margin-left: 5px;
`;
const ShortCell = styled.td`
  padding: 0.5rem !important;
`;
const MatchResultLink = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
`;

const TeamDetails = (props) => {
  const { teamDetail } = props;

  const TinyNAQ = () => {
    if (!teamDetail.team) return null;

    return nonAqTeamIds.includes(teamDetail.team.id) ? (
      <Tiny>[NAQ]</Tiny>
    ) : null;
  };

  const NameSubHeader = () => {
    if (!teamDetail.team) return null;

    return (
      <React.Fragment>
        <h5>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={`https://fumbbl.com/p/team?team_id=${teamDetail.team.id}`}
          >
            {teamDetail.team ? teamDetail.team.name : null}
          </a>
          <TinyNAQ />
        </h5>
        <p>
          {teamDetail.team.roster.name}
          <br />
          Coach: {teamDetail.team.coach.name}
        </p>
      </React.Fragment>
    );
  };

  const Schedule = () => {
    if (!teamDetail.team) return null;

    return (
      <React.Fragment>
        <SubHeader>
          Record:{" "}
          {`${teamDetail.wins}-${teamDetail.draws}-${teamDetail.losses}`}
        </SubHeader>
        <DetailsTable className="table table-striped">
          <thead className="thead-light">
            <tr>
              <th colSpan="3">Match Results</th>
            </tr>
          </thead>
          <tbody>
            {teamDetail.schedule
              .sort((a, b) => (a.matchId < b.matchId ? -1 : 1))
              .map((m) => (
                <ResultRow key={m.matchId} result={m.result}>
                  <ShortCell>
                    <MatchResultLink
                      target="_blank"
                      href={`https://fumbbl.com/FUMBBL.php?page=match&id=${m.matchId}`}
                    >
                      {m.opponentName}
                    </MatchResultLink>
                  </ShortCell>
                  <ShortCell>{m.result}</ShortCell>
                  <RecordCell>{`${m.tdFor}-${m.tdAgainst}`}</RecordCell>
                </ResultRow>
              ))}
          </tbody>
        </DetailsTable>
      </React.Fragment>
    );
  };

  function popover(player, newSkills) {
    const baseSkills = player.skills.filter((s) => !newSkills.includes(s));
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{player.name}</Popover.Title>
        <Popover.Content>
          <StatlineTable player={player} />
          <div className="txt-ctr">
            <span style={{ fontStyle: "italic" }}>
              {baseSkills.join(", ")}
              {baseSkills.length > 0 ? ", " : null}
            </span>
            {newSkills.join(", ")}
          </div>
        </Popover.Content>
      </Popover>
    );
  }

  const TopStars = () => {
    if (!teamDetail.team) return null;

    return (
      <React.Fragment>
        <SubHeader>Top Stars</SubHeader>
        <DetailsTable className="table table-striped">
          <thead className="thead-light">
            <tr>
              <th>Name</th>
              <th>SPP</th>
              <th>Position</th>
            </tr>
          </thead>
          <tbody>
            {teamDetail.topStars.map((s) => {
              const newSkills = teamDetail.team.players.find(
                (p) => p.id === s.id
              ).skills;
              return (
                <tr key={s.id}>
                  <ShortCell>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://fumbbl.com/p/player?player_id=${s.id}`}
                    >
                      {s.name}
                    </a>
                    <OverlayTrigger overlay={popover(s, newSkills)}>
                      <i
                        className="fas fa-info-circle ml-2"
                        style={{
                          fontSize: "small",
                          color: "rgba(0, 0, 0, 0.5)",
                        }}
                      ></i>
                    </OverlayTrigger>
                  </ShortCell>
                  <ShortCell>{s.statistics.spp}</ShortCell>
                  <ShortCell>{s.position.name}</ShortCell>
                </tr>
              );
            })}
          </tbody>
        </DetailsTable>
      </React.Fragment>
    );
  };

  return (
    <div>
      <NameSubHeader />
      <Schedule />
      <TopStars />
    </div>
  );
};

export default TeamDetails;
