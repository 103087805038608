import React, { useEffect, useState } from "react";
import pollData from "./Data/pollData";
import axios from "axios";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import abbreviations from "../../stats-files/abbr.json";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const SwitchButton = styled.button`
  float: right;
`;

const Breakdown = () => {
  const pollId = pollData.previousPoll.id;
  const seasonKey = pollId.split("-").pop();
  const seasonId = `ncbb-season-${seasonKey}`;

  const [pollBallots, setPollBallots] = useState(null);
  const [isPollBallotsLoaded, setIsPollBallotsLoaded] = useState(false);
  const [pollBreakdown, setPollBreakdown] = useState(null);
  const [isColumnsLoaded, setIsColumnsLoaded] = useState(false);
  const [columns, setColumns] = useState([]);
  const [teamData, setTeamData] = useState(null);
  const [isTeamDataLoaded, setIsTeamDataLoaded] = useState(false);

  useEffect(() => {
    if (seasonId != null) {
      axios
        .get(`/api/teamDetails?seasonId=${seasonId}`, {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        })
        .then((result) => {
          setTeamData(
            result.data.sort((a, b) => {
              if (a.points > b.points) return -1;
              if (a.points < b.points) return 1;
              return 0;
            })
          );
          setIsTeamDataLoaded(true);
        });
    }
  }, [seasonId]);

  useEffect(() => {
    axios
      .get(`/api/ballot?pollId=${pollId}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setIsPollBallotsLoaded(true);
        setPollBallots(result.data);
      });
  }, [pollId]);

  useEffect(() => {
    if (isPollBallotsLoaded && isTeamDataLoaded && pollBallots) {
      let coaches = {};

      pollBallots.forEach((p) => {
        const affDetail = teamData.find(
          (td) => td.team.coach.name === p.coachName
        );
        const aff = affDetail && affDetail.team.name;

        coaches[p.coachName] = {
          coach: p.coachName,
          teamsRanked: p.ballot.length,
          affiliation: aff,
        };

        p.ballot.forEach((b) => {
          console.log("b", b);
          const ab = abbreviations.find(
            (a) => a.id === parseInt(b.teamId)
          ).abbr;
          coaches[p.coachName][`rank${b.rank}`] = ab;
        });
      });

      setPollBreakdown(
        Object.values(coaches).sort((a, b) => {
          if (a.teamsRanked > b.teamsRanked) return -1;
          if (a.teamsRanked < b.teamsRanked) return 1;
          return 0;
        })
      );
    }
  }, [isPollBallotsLoaded, isTeamDataLoaded, teamData, pollBallots]);

  useEffect(() => {
    if (pollBreakdown && pollBreakdown.length > 0) {
      const columns = getDynamicColumns(pollBreakdown);
      setColumns(columns);
      setIsColumnsLoaded(true);
    }
  }, [pollBreakdown]);

  function getDynamicColumns(data) {
    const max = Math.max.apply(
      Math,
      data.map((d) => d.teamsRanked)
    );

    function getColumnWidth(accessor, headerText) {
      // let {data} = this.state;
      let max = 0;

      const maxWidth = 400;
      const magicSpacing = 10;

      for (var i = 0; i < data.length; i++) {
        if (data[i] !== undefined && data[i][accessor] !== null) {
          if (JSON.stringify(data[i][accessor] || "null").length > max) {
            max = JSON.stringify(data[i][accessor] || "null").length;
          }
        }
      }

      return Math.min(
        maxWidth,
        Math.max(max, headerText.length) * magicSpacing
      );
    }

    const cols = [
      {
        Header: "Coach",
        accessor: "coach",
        fixed: "left",
        // width: getColumnWidth("coach", "Coach"),
      },
      // {
      //   Header: "Affiliation",
      //   accessor: "affiliation",
      //   fixed: "left",
      //   // width: getColumnWidth("affiliation", "Affiliation"),
      // },
    ];

    for (let i = 1; i <= max; i++) {
      cols.push({
        Header: i.toString(),
        accessor: `rank${i}`,
        width: getColumnWidth(`rank${i}`, i.toString()),
        className: "txt-ctr team-abbr",
        Cell: (row) => {
          if (row.value) {
            const teamName = abbreviations.find(
              (a) => a.abbr === row.value
            ).name;
            return <abbr title={teamName}>{row.value}</abbr>;
          } else return row.value;
        },
      });
    }

    return cols;
  }

  const PollTable = () => {
    useEffect(() => {
      if (pollBreakdown) {
        document.querySelectorAll("div.team-abbr").forEach((cell) => {
          if (cell.children.length > 0) {
            cell.style.cursor = "pointer";
            cell.addEventListener("click", (e) => {
              const abbr =
                e.target === cell
                  ? cell.children[0].innerHTML
                  : e.target.innerHTML;

              Array.from(document.querySelectorAll("div.team-abbr"))
                .filter(
                  (c) =>
                    c.children.length > 0 && c.children[0].innerHTML === abbr
                )
                .forEach((bg) => bg.classList.add("lightBlue"));

              Array.from(document.querySelectorAll("div.team-abbr"))
                .filter(
                  (c) =>
                    c.children.length > 0 && c.children[0].innerHTML !== abbr
                )
                .forEach((bg) => bg.classList.remove("lightBlue"));
            });
          }
        });
      }
    }, []);

    if (!isColumnsLoaded)
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    else {
      const ReactTableFixedColumns = withFixedColumns(ReactTable);
      return (
        <ReactTableFixedColumns
          className="-striped -highlight"
          data={pollBreakdown}
          columns={columns}
          showPagination={false}
          sortable={false}
          defaultPageSize={pollBreakdown.length}
          style={{ fontSize: "13px", clear: "both" }}
        />
      );
    }
  };

  if (pollId == null) {
    return (
      <h3 className="txt-ctr">
        There is currently no poll data to display. Please return here after the
        voting period has completed to see the poll results.
      </h3>
    );
  } else {
    return (
      <React.Fragment>
        <Row>
          <Col>
            <div>
              <h2 className="float-left">
                NCBB Coaches Poll - Voter Breakdown
              </h2>
              <Link to="./">
                <SwitchButton className="btn btn-primary float-right">
                  Poll Ranking
                </SwitchButton>
              </Link>
            </div>
            <PollTable />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
};

export default Breakdown;
