import React, { useEffect, useState } from "react";
import ReactTable from "react-table-v6";
import "react-table-v6/react-table.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import axios from "axios";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StatCatNav = styled.li`
  padding: 8px;
  border: 1px solid darkgrey;
  border-radius: 3px;
  margin-right: 8px;
  background-color: lightgrey;
  color: black;
`;

const SingleSeasonLeaders = (props) => {
  const ReactTableFixedColumns = withFixedColumns(ReactTable);

  console.log("props", props);
  const leagueKey = props.match.params.leagueKey;
  console.log("leagueKey", leagueKey);
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const selectedStat = params.get("stat");
  const columnHeaders = {
    spp: "SPP Earned",
    touchdowns: "Touchdowns",
    casualties: "Casualties",
    blocks: "Blocks",
    rushing: "Rushing Yards",
    passing: "Passying Yards",
    fouls: "Fouls",
    interceptions: "Interceptions",
    turns: "Turns Played",
    completions: "Completions",
  };

  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [leadersData, setLeadersData] = useState(null);
  const [leadersForStat, setLeadersForStat] = useState(null);
  const [columnHeader, setColumnHeader] = useState("");

  const columns = [
    {
      Header: "",
      accessor: "statRank",
      width: 75,
    },
    {
      Header: "Name",
      accessor: "playerName",
      Cell: (row) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`https://fumbbl.com/p/player?player_id=${row.original.playerId}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Team",
      accessor: "teamName",
      Cell: (row) => (
        <a
          target="_blank"
          rel="noreferrer noopener"
          href={`https://fumbbl.com/p/team?team_id=${row.original.teamId}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Season",
      accessor: `seasonId`,
      className: "txt-ctr",
      width: 100,
      Cell: (row) => (row.value.split("-")[2])
    },
    {
      Header: columnHeader,
      accessor: `total`,
      className: "txt-rt",
      width: 150,
    }
  ];

  useEffect(() => {
    axios
      .get(`/api/leadersSingleSeason?leagueKey=${leagueKey.toLowerCase()}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setIsDataLoaded(true);
        setLeadersData(result.data[0]);
      });
  }, [leagueKey]);

  useEffect(() => {
    if (selectedStat !== null && isDataLoaded && leadersData) {
      var leaders = leadersData[selectedStat]
        .filter((s) => s.total > 0)
        .sort((a, b) => {
          if (
            a.total > b.total
          )
            return -1;
          if (
            a.total < b.total
          )
            return 1;
          return 0;
        });

      var stat = leaders.map((s) => s.total);

      leaders.forEach(
        (l) => (l.statRank = stat.indexOf(l.total) + 1)
      );

      setLeadersForStat(leaders);
    }
  }, [selectedStat, isDataLoaded, leadersData]);

  useEffect(() => {
    setColumnHeader(columnHeaders[selectedStat]);
  }, [selectedStat, columnHeaders]);

  const StatCatButtons = () => {
    return (
      <ul
        className="nav mb-2"
        style={{ display: "flex", "justify-content": "center" }}
      >
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=spp`}>
          <StatCatNav
            className={`nav-item ${selectedStat === "spp" ? "active" : ""}`}
          >
            SPP
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=touchdowns`}>
          <StatCatNav
            className={`nav-item ${
              selectedStat === "touchdowns" ? "active" : ""
            }`}
          >
            Touchdowns
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=casualties`}>
          <StatCatNav
            className={`nav-item ${
              selectedStat === "casualties" ? "active" : ""
            }`}
          >
            Casualties
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=blocks`}>
          <StatCatNav
            className={`nav-item ${selectedStat === "blocks" ? "active" : ""}`}
          >
            Blocks
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=rushing`}>
          <StatCatNav
            className={`nav-item ${selectedStat === "rushing" ? "active" : ""}`}
          >
            Rushing
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=passing`}>
          <StatCatNav
            className={`nav-item ${selectedStat === "passing" ? "active" : ""}`}
          >
            Passing
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=completions`}>
          <StatCatNav
            className={`nav-item ${
              selectedStat === "completions" ? "active" : ""
            }`}
          >
            Completions
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=fouls`}>
          <StatCatNav
            className={`nav-item ${selectedStat === "fouls" ? "active" : ""}`}
          >
            Fouls
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=interceptions`}>
          <StatCatNav
            className={`nav-item ${
              selectedStat === "interceptions" ? "active" : ""
            }`}
          >
            Interceptions
          </StatCatNav>
        </Link>
        <Link to={`/fumbbl/${leagueKey}/leadersSingleSeason?stat=turns`}>
          <StatCatNav
            className={`nav-item ${selectedStat === "turns" ? "active" : ""}`}
          >
            Turns
          </StatCatNav>
        </Link>
      </ul>
    );
  };

  const LeadersSingleSeasonTable = () => {
    if (isDataLoaded && leadersData && leadersForStat) {
      return (
        <ReactTableFixedColumns
          className="-striped -highlight"
          columns={columns}
          data={leadersForStat}
          sortable={false}
          showPagination={false}
          defaultPageSize={500}
          minRows={1}
        />
      );
    } else if (selectedStat) {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <h2>Select a stat category</h2>
        </div>
      );
    }
  };

  return (
    <React.Fragment>
      <h2 style={{ textAlign: "center" }}>{leagueKey} Single Season Leaders</h2>
      <Row>
        <Col className="col-xl-8 col-lg-12 m-auto">
          <StatCatButtons />
          <LeadersSingleSeasonTable />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default SingleSeasonLeaders;
