import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { DragDropContext } from "react-beautiful-dnd";
import recapData from "./Data/recapData";
import pollData from "./Data/pollData";
import PollColumn from "./PollColumn";
import DetailsColumn from "./DetailsColumn";
import axios from "axios";

const Recap = () => {
  const [pollBallots, setPollBallots] = useState(null);
  const [isPollBallotsLoaded, setIsPollBallotsLoaded] = useState(false);

  const [ballotObjData, setBallotObjData] = useState(null);

  const [teamData, setTeamData] = useState(null);
  const [isTeamDataLoaded, setIsTeamDataLoaded] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/ballot?pollId=${pollData.currentPoll.id}`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        setIsPollBallotsLoaded(true);
        setPollBallots(result.data);
      });

    axios
      .get(`/api/teamDetails?seasonId=ncbb-season-45`, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((result) => {
        console.log("teams", result.data);
        setTeamData(result.data);
        setIsTeamDataLoaded(true);
      });
  }, []);

  useEffect(() => {
    if (isPollBallotsLoaded && isTeamDataLoaded) {
      teamData.forEach((td) => (td.points -= 10 - td.schedule.length));

      teamData
        //.filter((t) => t.IsBowlEligible && t.schedule.length > 7)
        .filter((t) => t.IsBowlEligible)
        .sort((a, b) => {
          if (a.points < b.points) return 1;
          if (a.points > b.points) return -1;
          return 0;
        })
        .forEach((td, index) => {
          const stringId = td.team.id.toString();
          recapData.teams[stringId] = { ...td, id: stringId };
          recapData.pollColumns["all-teams"].teamIds.splice(index, 0, stringId);
        });

      setBallotObjData(recapData);
    }
  }, [isPollBallotsLoaded, isTeamDataLoaded, teamData, pollBallots]);

  function handleDragEnd(result) {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    if (
      destination.droppableId === "compare-left" ||
      destination.droppableId === "compare-right"
    ) {
      // view details
      const column = ballotObjData.detailColumns[destination.droppableId];
      const teamDetail = ballotObjData.teams[draggableId];

      const newColumn = {
        ...column,
        teamDetail: teamDetail,
      };

      const newballotObjData = {
        ...ballotObjData,
        detailColumns: {
          ...ballotObjData.detailColumns,
          [newColumn.id]: newColumn,
        },
      };

      setBallotObjData(newballotObjData);
    } else if (destination.droppableId === source.droppableId) {
      // same column
      const column = ballotObjData.pollColumns[source.droppableId];
      const newTeamIds = Array.from(column.teamIds);
      newTeamIds.splice(source.index, 1);
      newTeamIds.splice(destination.index, 0, draggableId);

      const newColumn = {
        ...column,
        teamIds: newTeamIds,
      };

      const newballotObjData = {
        ...ballotObjData,
        pollColumns: {
          ...ballotObjData.pollColumns,
          [newColumn.id]: newColumn,
        },
      };

      setBallotObjData(newballotObjData);
    } else {
      // between poll columns
      const sourceColumn = ballotObjData.pollColumns[source.droppableId];
      const destColumn = ballotObjData.pollColumns[destination.droppableId];
      const newSourceTeamIds = Array.from(sourceColumn.teamIds);
      const newDestTeamIds = Array.from(destColumn.teamIds);

      newSourceTeamIds.splice(source.index, 1);
      newDestTeamIds.splice(destination.index, 0, draggableId);

      const newSourceColumn = {
        ...sourceColumn,
        teamIds: newSourceTeamIds,
      };

      const newDestColumn = {
        ...destColumn,
        teamIds: newDestTeamIds,
      };

      const newballotObjData = {
        ...ballotObjData,
        pollColumns: {
          [newSourceColumn.id]: newSourceColumn,
          [newDestColumn.id]: newDestColumn,
        },
      };

      setBallotObjData(newballotObjData);
    }
  }

  const RecapColumns = () => {
    if (ballotObjData == null) {
      return (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      );
    }

    return (
      <React.Fragment>
        <Row>
          <Col>
            <h2 className="text-center">Season Recap</h2>
          </Col>
        </Row>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Row>
            {ballotObjData.pollColumnOrder.map((columnId, index) => {
              const column = ballotObjData.pollColumns[columnId];
              const teamDetails = column.teamIds.map(
                (teamId) => ballotObjData.teams[teamId]
              );

              return (
                <PollColumn
                  key={column.id}
                  column={column}
                  teamDetails={teamDetails}
                  showRank={index === 0}
                />
              );
            })}

            {ballotObjData.detailColumnOrder.map((columnId, index) => {
              const column = ballotObjData.detailColumns[columnId];
              const teamDetail = column.teamDetail;

              return (
                <DetailsColumn
                  key={column.id}
                  column={column}
                  teamDetail={teamDetail}
                />
              );
            })}
          </Row>
        </DragDropContext>
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <RecapColumns />
    </React.Fragment>
  );
};

export default Recap;
