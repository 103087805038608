const pollData = {
  currentPoll: {
    // id: "S45-postseason",
    id: null,
  },
  previousPoll: {
    id: "S45-postseason",
    // id: null,
  },
};

export default pollData;
