import React from "react";
import { Table, Row, Col } from "react-bootstrap";

const Rules = () => {
  return (
    <Row>
      <Col className="f-125">
        <h1>TOURNAMENT RULES</h1>
        <h3>Format and Teams Allowed</h3>
        <p>
          Any teams listed in Blood Bowl 2020 ruleset as defined by NAF rules
          for tournaments 2024, included the recommended rosters, are allowed
        </p>
        <ul>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.thenaf.net/wp-content/uploads/2023/12/NAF-Rules-for-Tournaments-2024.pdf"
            >
              https://www.thenaf.net/wp-content/uploads/2023/12/NAF-Rules-for-Tournaments-2024.pdf
            </a>
          </li>
          <li>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.warhammer-community.com/wp-content/uploads/2020/11/lFZy1SIuNmWvxPj1.pdf"
            >
              https://www.warhammer-community.com/wp-content/uploads/2020/11/lFZy1SIuNmWvxPj1.pdf
            </a>
          </li>
        </ul>
        <h3>Resurrection Tournament</h3>
        <p>All injuries will reset after each match.</p>
        <h3>Timing</h3>
        <p>
          The game round time limit WILL be strictly enforced. You will receive
          ‘Time Remaining’ updates from the Tournament Organizer to help you
          stay on pace.
        </p>
        <h3>Dice</h3>
        <p>
          You are required to share dice with your opponent if requested. Only
          GW or NAF Block Dice may be used.
        </p>
        <hr />
        <h1>SPECIAL RULES</h1>
        <h3>Dedicated Fans</h3>
        <p>
          Over the course of the tournament, certain events will increase your
          team’s Dedicated Fans permanently for the duration of the tournament.
          Your team’s Dedicated Fans will be used for tiebreakers in the
          tournament standings, and an award will be given to the team with the
          highest total Dedicated Fans at the end of the tournament.
        </p>
        <p>
          Your team’s Dedicated Fans will increase by 1 each time on of the
          following events occurs:
        </p>
        <ul>
          <li>Your team scores a Touchdown</li>
          <li>
            Your team causes a casualty via a Block or Foul action that would
            not normally generate SPP (Foul, Secret Weapon, Stab, crowd push)
          </li>
        </ul>
        <h3>Kick-Off Table</h3>
        <p>
          The Kick-Off table at the end of this ruleset will be used in place of
          the standard Kick-Off table. Several events on the Kick-Off table will
          be affected by your Fan Factor, representing the advantage given by
          your screaming fans.
        </p>
        <hr />
        <h1>
          TEAM BUILDING RULES{" "}
          <a
            style={{ fontSize: "1.25rem" }}
            href="https://docs.google.com/spreadsheets/d/1dJfCX1IIonc-npHZ4ZhjrTUoKJ5mzEbDhSTSoNuTgGg/edit?usp=sharing"
            rel="noopener noreferrer"
            target="_blank"
          >
            Roster Template
          </a>
        </h1>
        <p>
          You may build your team with 1140K GP. You may purchase players, Team
          Rerolls, Apothecary, Dedicated Fans, Assistant Coaches, Cheerleaders
          and Inducements. Allowed inducements are:
        </p>
        <ul>
          <li>Star Player - Masked Rider (See below)</li>
          <li>Any Biased Referees</li>
          <li>
            Any (In)famous Coaching Staff excluding Ayleen Adar, Schielund
            Scharlitan, Professor Fronkelheim
          </li>
          <li>
            Any other Inducements excluding Giants, Mercenaries, Special Plays,
            and Wizards or any other nonsense that GW comes out with or that I
            forgot. Just send your list early.
          </li>
        </ul>
        <p>
          <i>
            *NOTE: To be consistent with other SCARS Tournaments, each team will
            receive 1 Dedicated Fan for free when creating their roster.
          </i>
        </p>
        <p>
          In addtion to your 1140K, you are allowed to spend 160K GP to purchase
          additional skills as described below.
        </p>
        <h3>Stunty</h3>
        <p>
          To qualify for the Stunty Champion, your roster must not have any
          players without Stunty unless they are base ST5 or higher e.g.
          Halfings, Ogres, Goblins, Lizardmen with no Saurus, etc.
        </p>
        <h3>Star Player - The Masked Rider</h3>
        <p>
          Any team may include The Masked Rider as a Star Player. His cost,
          stats, and rules are identical to Rumblelow Sheepskin.
        </p>
        <p>
          You must have at least 11 players on your roster before purchasing
          this Star Player.
        </p>
        <p>
          There will be no "Highlander" rule in effect, so this star can play
          for both teams.
        </p>
        <p>Stunty teams are allowed to purchase this Star Player twice.</p>
        <h3>Additional Skills</h3>
        <p>
          There is a custom skill for this tournament called Fan Favorite
          described below.
        </p>
        <ul>
          <li>A primary skill upgrade costs 20,000</li>
          <li>A secondary skill upgrade costs 40,000</li>
          <li>You may purchase Fan Favorite for 20,000</li>
        </ul>
        <p>
          You may not assign more than two total skills to any single player.
          <br />
          No skill may be taken more than four times.
          <br />
          Fan Favorite may not be taken more than one time.
        </p>
        <h3>Fan Favorite Skill</h3>
        <p>
          If a Kick-Off table event requires you to roll 2D6, you instead roll
          3D6 and drop the lowest die if this player is on the pitch.
        </p>
        {/* <h3>Free Lineman</h3>
        <p>
          After you have finished building your team, you may add one player to
          your team from a 0-12 or 0-16 limit from your team roster at no GP
          cost. This cannot bring you above 16 players, and you must have at
          least 11 players on your roster before this player.
        </p> */}
        {/* <h3>Characteristic Improvement Packages</h3>
        <p>
          In addition to purchasing any skill(s) with treasury, each coach will
          choose one of the following packages at <u>no cost</u> for their team:
          <ul>
            <li>One player gains +ST</li>
            <li>
              One player gains +AG. You may assign two more primary skills to
              any players on your roster complying to all limitations above.
            </li>
            <li>
              One player gains +MA or +PA. You may assign one secondary skill
              and two primary skills to any players on your roster complying to
              all limitations above.
            </li>
          </ul>
        </p> */}
        <hr />
        <h1>SANCTIONED TOURNAMENT</h1>
        <p>
          This is a NAF approved tournament. More information can be found at{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.thenaf.net"
          >
            www.thenaf.net
          </a>
        </p>
        <p>
          This is part of the SCARS Blood Bowl tournament series. More
          information can be found at{" "}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="http://www.scarsbb.com"
          >
            www.scarsbb.com
          </a>
        </p>
        <hr />
        <h1>SCORING</h1>
        <h3>Standings</h3>
        <p>
          Standings will be determined by winning percentage. There is no
          championship game. Ties will be broken by Bonus Points described
          below.
        </p>
        <h3>Bonus Points</h3>
        <p>
          After each round you will fill out the results sheet based on the game
          just completed.
        </p>
        <ul>
          <li>1 bonus point for each touchdown scored (max 4)</li>
          <li>
            1 bonus point for each touchdown more than your opponent (max 4)
          </li>
          <li>
            1 bonus point for each casualty caused by any block or foul action
            (max 4)
          </li>
          <li>
            1 bonus point for each casualty caused by any block or foul action
            more than your opponent (max 4)
          </li>
          <li>If you finished the game by using all turns +4 bonus pts</li>
        </ul>
        <h3>Overtime</h3>
        <p>
          There will be no overtime played. If your game ends in a draw, it is a
          draw.
        </p>
        <h3>Tiebreakers</h3>
        <p>All ties will be broken in the following order</p>
        <ol>
          <li>Turning in your roster on time</li>
          <li>Highest bonus points</li>
          <li>Highest total Dedicated Fans</li>
          <li>Having a Fan Favorite</li>
          <li>Highest net Dedicated Fans increase</li>
          <li>Coin Toss</li>
        </ol>
        <h3>Painting</h3>
        <p>
          All teams are expected to meet the minimum painted guidelines. They
          must have 3 colors on them and have had some attempt at making them
          appear finished.
        </p>
        <h3>Sportsmanship</h3>
        <p>
          We are here for the fun of the game. Sportsmanship is not encouraged
          from the coaches it is required! Fouling 16 turns in a row and running
          up the score is not poor sportsmanship, but gloating and ridiculing
          your opponent while doing so is.
        </p>
        <hr />
        <h1>AWARDS</h1>
        <p>
          To be eligible for an award, you must have played all 4 rounds of the
          tournament. The following awards will be given out after the last
          round (subject to attendance):
        </p>
        <ul>
          <li>Champion</li>
          <li>Runner Up</li>
          <li>Best Offense (most TDs scored)</li>
          <li>Best Defense (least TDs allowed)</li>
          <li>Most Brutal (most CAS by block and foul actions)</li>
          <li>Stunty Champion (top placing Stunty team)</li>
          <li>Fan Favorite (Highest Dedicated Fans)</li>
          <li>Player’s Choice (Appearance, voted by peers)</li>
          <li>Best Sportsman (voted by peers)</li>
          <li>Dust In The Wind (lowest in standings)</li>
        </ul>
        <h3>Spread the Wealth</h3>
        <p>
          A coach may only win one award. If they are in line to win more than
          one award, they will be asked which one they would prefer and the next
          coach in line will be given the other award. Tournament organizers
          reserve the right to adjust the awards as deemed necessary.
        </p>
        <hr />
        <h1>
          KICK OFF TABLE&nbsp;
          <a
            style={{ fontSize: "1.25rem" }}
            href="https://docs.google.com/document/d/1MzSHsf7FFtgLaxMHblqOhkSGuuBWagLufjLZocaQvwU/edit?usp=sharing"
            rel="noopener noreferrer"
            target="_blank"
          >
            Printable Version
          </a>
        </h1>
        <Table striped bordered>
          <tbody>
            <tr>
              <td>
                <strong>2 Hold them back!</strong> – To contain the crowd,
                stadium security puts up barriers between the action on the
                field and the bleachers. For the rest of the game, if a player
                (unless being thrown by teammate) or bouncing ball would cross
                the sideline or end line, it stops in the square adjacent to the
                boundary. If it is a bouncing ball in the same square as a
                player, bounce it again until it would not go out of bounds. If
                a player would be pushed out of bounds, he instead is Knocked
                Down and +2 is added to the Armor roll.
                <br />
                <br />
                Any subsequent rolls of 2 or 12 on this table for the remainder
                of the game are treated as a Crowd-Fueled Frenzy.
              </td>
            </tr>
            <tr>
              <td>
                <strong>3 Smuggled Pogo Stick</strong> – Each coach rolls 2D6
                and adds their Fan Factor. The winning coach (or both in case of
                a tie) chooses a player on his team. The player(s) gains the
                Pogo Stick skill for the rest of the game. If the chosen
                player(s) has the Fan Favorite skill, they additionally gain the
                Sneaky Git skill for the rest of the game.
              </td>
            </tr>
            <tr>
              <td>
                <strong>4 Pitch Invasion (receiving)</strong> – Apply the Pitch
                Invasion result from the main rulebook Kick-Off event table
                except both coaches roll 2D6 instead of D6.
                <br />
                <br />
                If this result is chosen via Dueling Crowd Chants, the receiving
                team automatically wins the roll.
              </td>
            </tr>
            <tr>
              <td>
                <strong>5 Bountygate</strong> – If this result is chosen via
                Dueling Crowd Chants, the receiving team gains one team Reroll
                or one Bribe that is lost if not used by the end of the drive.
                <br />
                <br />
                Otherwise, each coach rolls 2D6 and adds their Fan Factor and
                the number of assistant coaches on their team. The coach with
                the higher total (or both if tied) gains a Bribe that is lost if
                not used by the end of the drive.
              </td>
            </tr>
            <tr>
              <td>
                <strong>6 Hurry-Up Offense</strong> – The receiving team chooses
                Quick Snap or High Kick and applies that result from the main
                rulebook kickoff table.
                <br />
                <br />
                If this result is chosen via Dueling Crowd Chants, apply both
                Quick Snap and High Kick.
              </td>
            </tr>
            <tr>
              <td>
                <strong>7 Dueling Crowd Chants</strong> – Each coach rolls 2D6
                and adds their Fan Factor to the roll.
                <ul>
                  <li>
                    If the total is tie or the coach with the higher total does
                    not choose to modify the Kick-Off Table result, treat this
                    result as Changing Weather from the main rulebook Kick-Off
                    event table.
                  </li>
                  <li>
                    Otherwise, the winning coach may choose to modify the Kick
                    Off Table result by up to 3 according to the difference in
                    the totals
                  </li>
                </ul>
                <Table className="w-50">
                  <thead>
                    <tr>
                      <th>Difference</th>
                      <th>Kicking</th>
                      <th>Receiving</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1-5</td>
                      <td>+1</td>
                      <td>-1</td>
                    </tr>
                    <tr>
                      <td>6-10</td>
                      <td>+2</td>
                      <td>-2</td>
                    </tr>
                    <tr>
                      <td>11+</td>
                      <td>+3</td>
                      <td>-3</td>
                    </tr>
                  </tbody>
                </Table>
              </td>
            </tr>
            <tr>
              <td>
                <strong>8 Intimidation Tactics</strong> – Randomly select one
                player on the receiving team. That player gains the Really
                Stupid trait for the next turn.
                <br />
                <br />
                If this result is chosen via Dueling Crowd Chants, randomly
                select an additional player on the receiving team. That player
                gains the Really Stupid trait for the next turn.
              </td>
            </tr>
            <tr>
              <td>
                <strong>9 Distracting Cheerleaders</strong> – If this result is
                chosen via Dueling Crowd Chants, the kicking team gains one team
                Reroll or one Bribe that is lost if not used by the end of the
                drive.
                <br />
                <br />
                Otherwise, each coach rolls 2D6 and adds their Fan Factor and
                the number of cheerleaders on their team. The coach with the
                higher total (or both if tied) gains a team Reroll that is lost
                if not used by the end of the drive.
              </td>
            </tr>
            <tr>
              <td>
                <strong>10 Pitch Invasion (kicking)</strong> – Apply the Pitch
                Invasion result from the main rulebook Kick-Off event table
                except both coaches roll 2D6 instead of D6.
                <br />
                <br />
                If this result is chosen via Dueling Crowd Chants, the kicking
                team automatically wins the roll.
              </td>
            </tr>
            <tr>
              <td>
                <strong>11 Crowd-Fueled Frenzy</strong> – For the remainder of
                the drive, +1 added to all armor rolls during Foul actions for
                both teams. Players with the Fan Favorite skill gain the Sneaky
                Git skill until the end of the drive.
              </td>
            </tr>
            <tr>
              <td>
                <strong>12 We can’t hold them any longer!</strong> – The crowd's
                frenzy compels them to encroach on the field to get closer to
                the action. All squares adjacent to the sideline count as out of
                bounds for the rest of the game.
                <br />
                <br />
                Any subsequent rolls of 2 or 12 on this table for the remainder
                of the game are treated as a Crowd-Fueled Frenzy.
              </td>
            </tr>
          </tbody>
        </Table>
      </Col>
    </Row>
  );
};

export default Rules;
