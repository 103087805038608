import React from "react";
import StatsTable from "./StatsTable";

const TeamHittingStats = (props) => {
  const { data } = props;

  data.map((r) => {
    r.calculated = {
      casPerBlock:
        r.seasonStatistics.casualties < 3
          ? null
          : r.seasonStatistics.casualties / r.seasonStatistics.blocks,
      casPlusMinus:
        r.seasonStatistics.casualties - r.seasonStatistics.casualtiesAgainst,
      blocksPlusMinus:
        r.seasonStatistics.blocks - r.seasonStatistics.blocksAgainst,
      blocksPerGame: r.seasonStatistics.blocks / r.seasonStatistics.games,
      blocksAgainstPerGame:
        r.seasonStatistics.blocksAgainst / r.seasonStatistics.games,
      blockDiffPerGame:
        (r.seasonStatistics.blocks - r.seasonStatistics.blocksAgainst) /
        r.seasonStatistics.games,
      totalCasualties:
        r.seasonStatistics.totalCasualties.bh +
        r.seasonStatistics.totalCasualties.si +
        r.seasonStatistics.totalCasualties.rip,
      totalCasualtiesAgainst:
        r.seasonStatistics.totalCasualtiesAgainst.bh +
        r.seasonStatistics.totalCasualtiesAgainst.si +
        r.seasonStatistics.totalCasualtiesAgainst.rip,
      totalCasualtiesPlusMinus:
        r.seasonStatistics.totalCasualties.bh +
        r.seasonStatistics.totalCasualties.si +
        r.seasonStatistics.totalCasualties.rip -
        r.seasonStatistics.totalCasualtiesAgainst.bh -
        r.seasonStatistics.totalCasualtiesAgainst.si -
        r.seasonStatistics.totalCasualtiesAgainst.rip,
      turnsPlusMinus:
        r.seasonStatistics.turns - r.seasonStatistics.turnsAgainst,
      turnsPerGame: r.seasonStatistics.turns / r.seasonStatistics.games,
      turnsAgainstPerGame:
        r.seasonStatistics.turnsAgainst / r.seasonStatistics.games,
      turnDiffPerGame:
        (r.seasonStatistics.turns - r.seasonStatistics.turnsAgainst) /
        r.seasonStatistics.games,
    };
    console.log("wtf", r);
    return r;
  });

  const columns = [
    { Header: "", accessor: "team.id", show: false },
    {
      Header: "Name",
      accessor: "team.name",
      fixed: "left",
      minWidth: 250,
      filterable: false,
      sortable: false,
      Cell: (row) => (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`https://fumbbl.com/p/team?team_id=${row.original.team.id}`}
        >
          {row.value}
        </a>
      ),
    },
    {
      Header: "Race",
      accessor: "team.roster.name",
      filterable: true,
      sortable: false,
      minWidth: 150,
    },
    {
      Header: "Cas",
      accessor: "seasonStatistics.casualties",
      className: "txt-rt",
    },
    {
      Header: "Cas Against",
      accessor: "seasonStatistics.casualtiesAgainst",
      className: "txt-rt",
      defaultSortDesc: false,
    },
    {
      Header: "Cas +/-",
      accessor: "calculated.casPlusMinus",
      className: "txt-rt",
    },
    {
      Header: "Blocks",
      accessor: "seasonStatistics.blocks",
      className: "txt-rt",
    },
    {
      Header: "Blocks Against",
      accessor: "seasonStatistics.blocksAgainst",
      className: "txt-rt",
      defaultSortDesc: false,
    },
    {
      Header: "Block +/-",
      accessor: "calculated.blocksPlusMinus",
      className: "txt-rt",
    },
    {
      Header: "Blocks PG",
      accessor: "calculated.blocksPerGame",
      className: "txt-rt",
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "Blocks Against PG",
      accessor: "calculated.blocksAgainstPerGame",
      className: "txt-rt",
      defaultSortDesc: false,
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "Block +/- PG",
      accessor: "calculated.blockDiffPerGame",
      className: "txt-rt",
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "BPCas",
      accessor: "calculated.casPerBlock",
      className: "txt-rt",
      Cell: (row) => (row.value === null ? null : (1 / row.value).toFixed(3)),
    },
    // {
    //   Header: "Total Cas",
    //   accessor: "calculated.totalCasualties",
    //   className: "txt-rt",
    // },
    // {
    //   Header: "Total Cas Against",
    //   accessor: "calculated.totalCasualtiesAgainst",
    //   className: "txt-rt",
    // },
    // {
    //   Header: "Total Cas +/-",
    //   accessor: "calculated.totalCasualtiesPlusMinus",
    //   className: "txt-rt",
    // },
    {
      Header: "Fouls",
      accessor: "seasonStatistics.fouls",
      className: "txt-rt",
    },
    {
      Header: "Turns",
      accessor: "seasonStatistics.turns",
      className: "txt-rt",
    },
    {
      Header: "Turns Against",
      accessor: "seasonStatistics.turnsAgainst",
      className: "txt-rt",
      defaultSortDesc: false,
    },
    {
      Header: "Turns +/-",
      accessor: "calculated.turnsPlusMinus",
      className: "txt-rt",
    },
    {
      Header: "Turns PG",
      accessor: "calculated.turnsPerGame",
      className: "txt-rt",
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "Turns Against PG",
      accessor: "calculated.turnsAgainstPerGame",
      className: "txt-rt",
      defaultSortDesc: false,
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "Turns +/- PG",
      accessor: "calculated.turnDiffPerGame",
      className: "txt-rt",
      Cell: (row) => (row.value === null ? null : row.value.toFixed(3)),
    },
    {
      Header: "Games",
      accessor: "seasonStatistics.games",
      className: "txt-rt",
    },
  ];

  return (
    <StatsTable
      columns={columns}
      data={data}
      defaultSorted={[{ desc: true, id: "seasonStatistics.casualties" }]}
    />
  );
};

export default TeamHittingStats;
