import React from "react";
import { Row, Col } from "react-bootstrap";

const Venue = () => (
  <Row>
    <Col className="f-150 col-12 col-lg-6">
      <h1>Stormcrow Games</h1>
      <img
        alt="Stormcrow Games"
        className="bg-black"
        src="https://www.stormcrow-games.com/wp-content/uploads/2018/07/logowebsite.png"
      />
      <p>
        2155 A 50th St
        <br />
        Lubbock, TX 79412
      </p>
      <p>806-795-0154</p>
      <p>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.stormcrow-games.com"
        >
          https://www.stormcrow-games.com
        </a>
      </p>
    </Col>
    <Col className="col-12 col-lg-6">
      <iframe
        title="stormcrow-google-map"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d26601.56575255752!2d-101.8824614629094!3d33.54829085156579!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86fe6d44a192cb49%3A0xf046fbd5e44d4a3a!2sStormcrow%20Games!5e0!3m2!1sen!2sus!4v1622398375506!5m2!1sen!2sus"
        width="100%"
        max-width="600"
        height="450"
        allowFullScreen=""
        loading="lazy"
      ></iframe>
    </Col>
  </Row>
);

export default Venue;
